<template>
  <div :class="`text-${textVariant}`">
    <!-- <h5>
      <v-icon large :class="`mr-2 text-${textVariant}`">mdi-account-cog-outline</v-icon>
      Preferences
    </h5> -->
    <a href="https://help.mythulu.ink/" target="_blank">
      https://help.mythulu.ink/
      <v-icon x-small :dark="dark">mdi-open-in-new</v-icon>
    </a>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState([
      'providers',
    ]),
    ...mapGetters([
      'dark',
      'textVariant',
    ]),
  },
};
</script>
