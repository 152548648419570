export const rounded = {
  '.rounded': {
    'border-radius': '4px',
  },
  '.rounded-top': {
    'border-radius': '4px',
    'border-bottom-left-radius': '0px !important',
    'border-bottom-right-radius': '0px !important',
  },
};
