<template>
  <div class="h-100 d-flex flex-column">
    <b-row no-gutters class="justify-content-center" style="margin-top: 280px;">
      <b-col
        v-for="(Area, index) in orderedAreas"
        :key="Area.area"
        :class="`col-md-auto d-flex justify-content-top mr-${Area.shy ? 0 : 3}`"
        style="font-size: 1.2rem"
      >
        <span v-if="index > 0 && !Area.shy" class="ml-5"> • </span>
        <span v-if="!($store.state[Area.area] || {}).ready">
          <router-link
            :class="{
              'text-center d-inline-block ml-5 cursor-pointer': true,
              'text-decoration-none text-neutral': true,
            }"
            :to="{ name: 'home' }"
            disabled
          >
            <div>
              <span class="text-muted"
              >{{ $t(Area.area) }}</span>
            </div>
            <v-icon
              style="font-size: 55px;"
              :class="`slow-rock text-${Area.variant}`"
            >mdi-timer-sand</v-icon>
          </router-link>
        </span>
        <span v-if="!Area.shy && ($store.state[Area.area] || {}).ready">
          <router-link
            :class="{
              'text-center d-inline-block ml-5 cursor-pointer': true,
              [`text-decoration-none text-${textVariant}`]: true
            }"
            :to="
              ($store.state[Area.area] || {}).ready ? { name: Area.area } : {}
            "
          >
            <div>
              <span :class="`border-bottom border-${Area.variant}`"
              >{{ $t(Area.area) }}</span>
            </div>
            <v-icon
              style="font-size: 55px;"
              :class="{
                [`area-link text-${Area.variant}`]: true,
                'opacity-2': !$store.state[Area.area].ready,
              }"
            >{{ Area.icon }}</v-icon>
          </router-link>
        </span>
      </b-col>
    </b-row>
    <div class="mt-auto mb-5 text-center">
      <code>[main] v{{ VERSION }} ink © Mythulu Inc. </code>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import { version } from '@/../package.json'

export default {
  data: () => ({
    VERSION: '0.1.0a',
  }),
  computed: {
    ...mapState([
      'providers',
    ]),
    ...mapGetters([
      'orderedAreas',
      'textVariant',
      'themeVariant',
    ]),
  },
}
</script>
