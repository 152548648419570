<template>
  <b-button
    :class="{
      'source-header py-2 text-left font-weight-bold border-0': true,
      [`px-0`]: !expanded,
      [`px-3 text-light bg-${variant}`]: isActive,
      [`mx-0 text-${inactiveVariant} text-${textVariant}`]: !isActive,
      'bg-deep-dark': !isActive && dark,
      'bg-deep-light': !isActive && !dark,
    }"
    :style="expanded && themeStyle('.rounded')"
    @click="select"
  >
    <b-row no-gutters>
      <b-col>
        <v-icon
          v-if="icon"
          :dark="!dark"
          :title="text"
          :class="{
            'align-baseline mx-1': true,
            [`text-light`]: isActive,
            [`text-${inactiveVariant} bg-transparent`]: !isActive,
          }"
          style="margin-top: 2px;"
        >{{ icon }}</v-icon>
        <span
          class="align-top"
          :style="`text-shadow: '0 1px 0 rgba(255, 255, 255, 0.5)`"
        >{{ text }}</span>
      </b-col>
      <b-col v-if="toggleable" cols="auto">
        <v-icon
          v-show="isActive"
          @click.stop="toggle"
          :class="{
            'flip-x cursor-pointer': true,
            'text-light': isActive,
          }">mdi-minus</v-icon>
      </b-col>
    </b-row>
  </b-button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    /** Nav name */
    nav: String,
    /** Nav source name */
    source: String,
    /** Button text */
    text: String,
    /** 'mdi-' prefixed icon name */
    icon: String,

    /** Source is active */
    active: { type: Boolean, default: false },
    /** Whole nav is showing items */
    expanded: { type: Boolean, default: true },
    /** Can collapse whole nav */
    toggleable: { type: Boolean, default: true },
    /** Activity-controlled variant */
    variant: { type: String, default: 'primary' },
  },
  computed: {
    ...mapGetters([
      'dark',
      'textVariant',
      'themeStyle',
    ]),
    isActive() {
      return this.expanded && this.active;
    },
    inactiveVariant() {
      return this.dark ? 'light' : 'muted';
    },
  },
  methods: {
    select() {
      if (!this.active) {
        this.$emit('select', this.source);
      }
      if (this.toggleable) {
        this.toggle();
      }
    },
    toggle() {
      this.$emit('expand', !this.isActive);
    },
  },
};
</script>
