<template>
  <b-tabs card
    active-nav-item-class="bg-transparent border-0"
    active-tab-class="opacity-5"
  >
    <b-tab>
      <template #title><v-icon :dark="dark">mdi-account-cog-outline</v-icon></template>
      <General/>
    </b-tab>
    <b-tab :active="!!pluginInstallQueue.length">
      <template #title>
        <b-spinner
          v-show="pluginInstallQueue.length"
          :variant="textVariant"
          class="position-absolute"
          style="margin: -3px 0 0 -4px;"
        ></b-spinner>
        <v-icon :dark="dark">mdi-power-plug-outline</v-icon>
      </template>
      <Plugins/>
    </b-tab>
    <!-- <b-tab>
      <template #title><v-icon :dark="dark">mdi-account-cog-outline</v-icon></template>
      <Telemetry/>
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import General from './settings/General.vue'
import Plugins from './settings/Plugins.vue'
// import Telemetry from './settings/Telemetry.vue'

export default {
  name: 'Settings',
  computed: {
    ...mapState([
      'area',
      'providers',
      'pluginInstallQueue',
    ]),
    ...mapGetters([
      'themeVariant',
      'textVariant',
      'dark',
    ]),
  },
  methods: {
    ...mapActions([
      'clearState',
    ]),
    resetCache() {
      this.clearState({ area: this.area });
      window.location.reload();
    },
  },
  components: {
    General,
    Plugins,
    // Telemetry,
  },
};
</script>
