<template>
  <div :class="`h-100 d-flex flex-column bg-${bgVariant || themeVariant}`">
    <slot name="header"></slot>

    <div id="frame" ref="frame"
      :class="`flex-grow-1 bg-${bgVariant || themeVariant}`"
      :style="`overflow-y: scroll; height: ${editorFullscreen ? '100vh' : '0px'}`"
    >
      <slot name="content" :frame="$refs.frame"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    bgVariant: { type: String, optional: true },
  },
  computed: {
    ...mapGetters([
      'themeVariant',
      'editorFullscreen',
    ]),
  },
};
</script>
