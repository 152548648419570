import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form',{class:`p-2 d-flex bg-${_vm.themeVariant}`,attrs:{"inline":""},on:{"submit":function($event){$event.preventDefault();}}},[_c(VIcon,{class:{
      'mr-1': true,
      'disabled cursor-default': _vm.disabled,
      'text-muted cursor-pointer': !_vm.disabled,
    },attrs:{"dark":_vm.dark},on:{"click":function($event){!_vm.disabled && _vm.newItem()}}},[_vm._v("mdi-plus")]),_c('b-form-input',{directives:[{name:"hotkey",rawName:"v-hotkey",value:(Object.assign({}, ..._vm.hotkeys.map(
      key => ({
        [key]: () => !_vm.disabled && _vm.newItem(),
      }),
    ))),expression:"Object.assign({}, ...hotkeys.map(\n      key => ({\n        [key]: () => !disabled && newItem(),\n      }),\n    ))"}],class:{
      'flex-grow-1 slim rounded border-0': true,
      [`bg-search${_vm.dark ? '-dark' : ''} text-${_vm.textVariant}`]: true,
    },attrs:{"id":`nav-${_vm.name}-new`,"disabled":_vm.disabled,"placeholder":_vm.disabled
      ? `Select a ${_vm.typeName}`
      : `Add ${_vm.typeName} ${_vm.next} ${_vm.hotkeys[0] || ''}`,"size":"sm","autocomplete":"off"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;!_vm.disabled && _vm.newItem()}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }