<template>
  <b-navbar id="titlebar"
    class="p-0"
    :style="Object.assign(
      themeStyle('#titlebar'),
      { height: editorFullscreen ? 0 : 'auto' }
    )"
  >
    <b-row no-gutters
      :class="{
        [`text-${textVariant} w-100 d-flex justify-content-between`]: true,
      }"
      :style="{ height: editorFullscreen ? 0 : 'auto' }"
    >
      <b-col md="auto" id="title-area">
        <b-row no-gutters class="left-size">
          <b-col :class="{
            'window-controls pl-3 pr-0': true,
            'mt-3': isMacOS,
          }" :style="isMacOS ? 'padding-left: 20px!important' : ''" md="auto">
            <!-- <v-icon x-large :dark="dark" class="">mdi-menu</v-icon> -->
          </b-col>
          <b-col><slot name="title" /></b-col>
        </b-row>
      </b-col>
      <b-col md v-show="!editorFullscreen">
        <b-row no-gutters>
          <b-col md="auto" id="left-gutter" align="right">
            <slot name="actions" />
          </b-col>
          <b-col md>
            <b-nav id="nav-areas"
              class="px-3"
              align="right"
              :style="themeStyle('#nav-areas')"
            >
              <slot name="areas"/>
              <b-nav-text id="os-windows" v-if="isElectron && !isMacOS">
                <v-icon :dark="dark" small
                  :class="{
                    [`ml-${area ? 1 : 3} mr-2 mt-1 align-top`]: true,
                  }"
                  @click="window.controls.minimize"
                >mdi-window-minimize</v-icon>
                <v-icon :dark="dark" small class="mx-2 mt-1 align-top" @click="window.controls.maximize"
                >mdi-window-maximize</v-icon>
                <v-icon :dark="dark" small class="mx-2 mt-1 align-top" @click="window.controls.close"
                >mdi-window-close</v-icon>
              </b-nav-text>
            </b-nav>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TitleBar',
  computed: {
    ...mapGetters([
      'textVariant',
      'themeVariant',
      'dark',
      'editorFullscreen',
      'themeStyle',
    ]),
    isElectron: () => window.device.isElectron(),
    isMacOS: () => window.device.isMacOS(),
  },
};
</script>
