<template>
  <b-row no-gutters class="mt-2">
    <b-col cols="1" class="text-center">
      <label v-if="filter" :for="`nav-${nav}-user-filter`" class="m-0">
        <v-icon
          small
          :class="`text-${variant}`"
          style="margin-top: -2px;"
          @click="clearFilter"
        >mdi-close-circle-outline</v-icon>
      </label>
      <v-icon v-else
        :dark="dark"
        :class="`text-${variant}`"
        style="margin-top: -2px;"
      >mdi-magnify</v-icon>
    </b-col>
    <b-col>
      <b-input
        :id="`nav-${nav}-user-filter`"
        v-model="filterAccessor"
        placeholder="Search..."
        autocomplete="off"
        size="sm"
        :class="{
          [`slim rounded border-0 text-${textVariant}`]: true,
          'bg-search': !dark,
          'bg-search-dark': dark,
        }"
      ></b-input>
    </b-col>
    <b-col cols="1" class="text-center">
      <v-icon
        @click="toggleFilterResults"
        :class="`text-${filterResults ? variant : textVariant}`"
        :title="$tc('nav-filter-enabled', Number(filterResults) + 1)"
      >mdi-filter</v-icon>
    </b-col>
    <b-col cols="1" class="text-center">
      <v-icon :id="`nav-${nav}-columns`"
        :dark="dark"
        :title="$t('nav-sort-options')"
        class="cursor-pointer"
      >mdi-sort-variant</v-icon>
    </b-col>
    <b-col cols="1" class="text-center">
      <v-icon :id="`nav-${nav}-history`"
        :dark="dark"
        :title="$tc('undo', Number(filterResults) + 1)"
      >mdi-history</v-icon>
    </b-col>

    <b-popover
      :target="`nav-${nav}-history`"
      triggers="focus click"
      :variant="dark ? variant : themeVariant"
    >
      <template #title>
        History
      </template>
      <div class="controls columns-config">
        <HistoryList
          :sources="[source]"
        />
      </div>
    </b-popover>

    <b-popover
      :target="`nav-${nav}-columns`"
      triggers="focus click"
      :variant="dark ? variant : themeVariant"
    >
      <template #title>
        Display
      </template>
      <div class="controls columns-config">
        <ColumnPicker
          :nav="nav"
          :source="source"
          :columns="columns"
        />
        <HierarchyPicker
          :nav="nav"
          :source="source"
          :folds="folds"
          :idParam="foldParam"
        />
      </div>
    </b-popover>
  </b-row>
</template>

<script>
import { sortBy } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import HistoryList from '@/components/history/List.vue'
import ColumnPicker from './ColumnPicker.vue'
import HierarchyPicker from './HierarchyPicker.vue'

export default {
  props: {
    nav: String,
    source: String,
    filter: String,
    variant: { type: String, default: 'primary' },
    filterResults: { type: Boolean, default: true },
    historyPlacement: { type: String, default: 'righttop' },
    columnsPlacement: { type: String, default: 'righttop' },
  },
  computed: {
    ...mapGetters([
      'dark',
      'textVariant',
      'themeVariant',
    ]),
    ...mapState({
      columns({}, { nav }) {
        return sortBy(nav[this.nav].columns[this.source], ['order']);
      },
      folds({}, { nav }) {
        return nav[this.nav].folds;
      },
      foldParam({}, { nav }) {
        return nav[this.nav].selected[this.source];
      },
    }),
    filterAccessor: {
      get() {
        return this.filter;
      },
      set(v) {
        this.setFilter(v);
      },
    },
  },
  methods: {
    setFilter(v) {
      this.$emit('set-filter', v);
    },
    clearFilter() {
      this.$emit('set-filter', '');
    },
    toggleFilterResults() {
      this.$emit('set-filter-results', !this.filterResults);
    },
  },
  components: {
    ColumnPicker,
    HierarchyPicker,
    HistoryList,
  },
};
</script>
