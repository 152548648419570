<template>
  <div>
    <vc-calendar
      title-position="right"
      :attributes="attributes"
      :is-dark="dark"
      :is-expanded="fill"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    layers: Array,
    fill: { type: Boolean, default: false, optional: true },
  },
  computed: {
    ...mapGetters([
      'dark',
      'areaVariant',
    ]),
    attributes() {
      return [
        {
          key: 'today',
          dates: new Date(),
          highlight: {
            color: '',
            class: `bg-${this.areaVariant}`,
          },
        },
        ...this.layers,
      ];
    },
  },
};
</script>
