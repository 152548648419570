<template>
  <form action="https://script.google.com/macros/s/AKfycbzqQm9pFq2gf4e-qsIgC11NJ9eeVA82o_C3cQ87JlHfz_HxujiStfMJoaMbPpT57q5u/exec" method="post">
    {{ $t('Report') }}
    <Report/>
  </form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Report from './issue-tracker/Report.vue';

export default {
  name: 'IssueTracker',
  computed: {
    ...mapState([
      'area',
      'providers',
      'pluginInstallQueue',
    ]),
    ...mapGetters([
      'themeVariant',
      'textVariant',
      'dark',
    ]),
  },
  methods: {
    ...mapActions([
      'clearState',
    ]),
  },
  components: {
    Report,
  },
};
</script>
