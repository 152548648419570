<template>
  <b-card
    no-body
    class="mx-auto my-3 overflow-hidden text-left"
    :bg-variant="themeVariant"
    :text-variant="textVariant"
    style="border: none;"
  >
    <b-row no-gutters>
      <b-col cols="2">
        <div
          :class="`position-absolute rounded-circle p-1 bg-${themeVariant}`"
          style="right: -5px; bottom: -5px;"
        ><v-icon :class="`position-relative text-${textVariant}`"
          style="top: -1px;"
          >mdi-{{ icons[authProviderId] }}</v-icon
        ></div>
        <b-card-img alt="Profile Image"
          :src="user.photoURL"
          :class="`rounded-circle border border-deep-${themeVariant}`"
          style="border-width: 3px !important;"
        ></b-card-img>
      </b-col>
      <b-col>
        <b-card-text class="px-3">
          <h3 class="my-0">{{ user.displayName || 'Anonymous' }}</h3>
          <div class="small">{{ user.email }}</div>
        </b-card-text>
      </b-col>
    </b-row>
    <b-row no-gutters class="small">
      <b-col cols="2" class="text-center">
        <a href="#" @click="logOut()" :class="`text-${textVariant}`" style="text-decoration: none;">Log out</a>
      </b-col>
      <b-col class="px-3">
        Connected via {{ authProviderId }}.
      </b-col>
    </b-row>
    <b-row v-if="relayAuthToElectron" class="mt-3">
      <b-col>
        <b-button size="lg" block
          :href="`mythulu-ink://auth/${authProviderId}/${credential}`"
        >
          Launch
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { icons } from '@/firebase';

export default {
  props: {
    relayAuthToElectron: Boolean,
    credential: String,
  },
  data: () => ({
    icons,
  }),
  computed: {
    ...mapState([
      'user',
    ]),
    ...mapGetters([
      'textVariant',
      'themeVariant',
      'authProviderId',
    ]),
  },
  methods: {
    ...mapActions([
      'logOut',
    ]),
  },
};
</script>
