<template>
  <div>
    <h6 class="display-5 text-center">Columns</h6>
    <!-- <Draggable
      tag="b-list-group"
      ghost-class="placeholder-from"
      v-model="columnsModel"
      flush
      animation
    > -->
    <span class="help-message">{{ $t('nav-column-picker-help') }}</span>
    <b-list-group>
      <!-- <transition-group type="transition" name="flip-list"> -->
        <b-list-group-item
          v-for="info in availableColumns"
          :key="info.name"
          :variant="info.locked
            ? activityVariant
            : info.visible
              ? activityVariant
              : themeVariant
          "
          :class="{
            'p-1': true,
            strong: info.visible,
            'cursor-default': info.locked,
            'cursor-pointer': !info.locked,
          }"
          @click.stop="toggleVisible(info)"
        >
          <!-- <v-icon x-small :class="`mr-1 text-${info.visible ? activityVariant : 'muted'}`">mdi-reorder-horizontal</v-icon> -->
          <!-- <v-icon small v-if="info.locked || info.sortLocked === true"
            :class="`text-${activityVariant}`"
          >mdi-lock</v-icon>
          <v-icon small v-else-if="!info.sort"
            :class="`text-${info.visible ? activityVariant : 'muted'}`"
            style="opacity: 0.5"
            @click.stop="info.sort = advanceSort(info.sort)"
          >mdi-sort-alphabetical-variant</v-icon>
          <v-icon small v-else-if="info.sort === 'asc'"
            :class="`text-${info.visible ? activityVariant : 'muted'}`"
            @click.stop="info.sort = advanceSort(info.sort)"
          >mdi-sort-variant</v-icon>
          <v-icon small v-else-if="info.sort === 'desc'"
            :class="`text-${info.visible ? activityVariant : 'muted'}`"
            @click.stop="info.sort = advanceSort(info.sort)"
          >mdi-sort-reverse-variant</v-icon> -->
          <span>
            <v-icon small v-if="info.locked"
              :class="`text-${activityVariant}`"
            >mdi-lock</v-icon>
            {{ info.name }}
          </span>
        </b-list-group-item>
      <!-- </transition-group> -->
    </b-list-group>
    <!-- </Draggable> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import Draggable from 'vuedraggable'

export default {
  props: {
    nav: { type: String },
    source: { type: String },
    columns: { type: Array },
  },
  computed: {
    ...mapGetters([
      'dark',
      'activityVariant',
      'themeVariant',
    ]),
    columnsModel: {
      get() {
        return this.columns;
      },
      set(columns) {
        this.configureNav({ name: this.nav, source: this.source, columns });
      },
    },
    availableColumns() {
      return this.columns.filter(col => !col.hidden);
    },
  },
  methods: {
    ...mapActions([
      'configureNav',
    ]),
    advanceSort(sort) {
      switch(sort) {
        case 'asc': return 'desc';
        case 'desc': return undefined;
        default: return 'asc';
      }
    },
    toggleVisible(col) {
      if (!col.locked) {
        col.visible = !col.visible;
      }
    },
  },
  components: {
    // Draggable,
  },
}
</script>
