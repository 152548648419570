<template>
  <b-row class="m-0 w-100 h-100">
    <transition name="pull-in-left">
      <b-col
        md="auto"
        :class="`p-0 h-100 left-size`"
        style="overflow: hidden;"
        v-show="!editorFullscreen"
      >
        <div
          :class="`d-flex flex-column h-100 bg-${themeVariant}`"
          :style="Object.assign({},
            themeStyle('.rounded'),
            themeStyle('#sidebar-left'),
          )"
        >
          <slot name="nav">[NAV]</slot>
        </div>
      </b-col>
    </transition>
    <b-col class="p-0 h-100">
      <div
        class="h-100 mx-auto"
        :style="Object.assign({},
          themeStyle('#content'),
          { width: workspace.width || '900px' }
        )"
      >
        <slot name="content">[CONTENT]</slot>
      </div>
    </b-col>
    <transition name="pull-in-right">
      <b-col
        md="auto"
        class="p-0 h-100 right-size"
        style="overflow: hidden auto;"
        v-show="!editorFullscreen"
      >
        <div
          class="d-flex flex-column h-100"
          :style="themeStyle('#sidebar-right')"
        >
          <slot name="sidebar">[SIDEBAR]</slot>
        </div>
      </b-col>
    </transition>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'workspace',
      'themeVariant',
      'editorFullscreen',
      'themeStyle',
    ]),
  },
}
</script>
