import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"nav-label text-nowrap"},[(_vm.icon || (_vm.userIcon && !_vm.user.photoURL))?_c(VIcon,{class:{
      'mr-2': true,
      [`text-${_vm.disabled
        ? _vm.dark ? 'dark' : 'light'
        : _vm.active
          ? _vm.activeTextVariant || 'light'
          : _vm.iconVariant || _vm.globalTextVariant
      }`]: true,
    }},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),(_vm.showLabel)?_c('span',{class:{
      [`d-inline-block clip-ellipsis`]: true,
      [`text-${_vm.disabled
        ? _vm.dark ? 'dark' : 'light'
        : _vm.active
          ? _vm.activeTextVariant || 'light'
          : _vm.textVariant || _vm.globalTextVariant
      }`]: true,
      'strong': _vm.strong,
    }},[_vm._v(" "+_vm._s(_vm.$t(_vm.text || _vm.meta.activity))+" ")]):_vm._e(),(_vm.userIcon && _vm.user.photoURL)?_c('img',{staticClass:"mt-2 ml-2 align-top rounded-circle border border-dark",attrs:{"width":"24","src":_vm.user.photoURL}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }