import { filter, get, first } from 'lodash';
import Vue from 'vue';
import Router from 'vue-router';
import AreaSelect from '@/components/AreaSelect.vue'
import store from '@/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'home', component: AreaSelect },
  ],
  scrollBehavior: to => to.hash ? { selector: to.hash } : { x: 0, y: 0 }
});

function getFirst(items, dottedPath) {
  return get(first(filter(items, record => get(record, dottedPath, null))), dottedPath);
}

router.beforeEach(async (to, from, next) => {
  store.dispatch('setRoute', to);
  store.dispatch('setArea', getFirst(to.matched, 'meta.area', null));
  store.dispatch('setActivity', getFirst(to.matched, 'meta.activity', null));
  store.dispatch('setActivityOverview', getFirst(to.matched, 'meta.overview', null));
  store.dispatch('setTask', getFirst(to.matched, 'meta.task', null));
  store.dispatch('setPhase', getFirst(to.matched, 'meta.phase', null));
  next();
});

export default router;
