<template>
  <WindowLayout>

    <template #title>
      <b-row v-if="!editorFullscreen" no-gutters id="logo-area">
        <b-col md="auto">
          <router-link :to="{ name: 'home' }">
            <div
              id="logo"
              class="d-none d-lg-block"
              :style="themeStyle('#logo')"
            ><MythuluLogo /></div>
            <div id="ink-area" class="w-100">
              <transition name="active-area">
                <span v-if="area"
                  :class="`text-${areaVariant}`"
                >
                  <span :style="themeStyle('#ink-area')">
                    <v-icon :dark="dark" small
                      :class="`text-${areaVariant}`"
                    >{{ currentArea.icon }}</v-icon>
                    {{ $t(area) }}
                  </span>
                  <span
                    :class="`text-dark`"
                    :style="themeStyle('#logo')"
                  >{{ version_display }}</span>
                </span>
              </transition>
            </div>
          </router-link>
        </b-col>
        <b-col md="auto" id="dot-ink">
          <router-link :to="{ name: 'home' }" class="underline-0">
            <strong :class="{
              [`text-${
                activityVariant || currentArea.variant || textVariant
              }`]: true,
            }">.ink</strong>
          </router-link>
        </b-col>
        <b-col>
          <v-icon small v-show="!editorFullscreen && !connected"
            class="ml-3 mr-2 spinner"
            :dark="dark"
          >mdi-timer-sand</v-icon>
        </b-col>
      </b-row>
    </template>

    <template #actions>
      <!-- <v-icon large v-show="!editorFullscreen"
        :dark="dark"
        @click="$router.back()"
      >mdi-keyboard-backspace</v-icon> -->
    </template>

    <template #areas>
      <b-nav-item v-if="route.params.projectId"
        :to="{
          name: activityOverview || (
            workspaceItem ? workspaceItem.activity : false
          ),
          params: { projectId: route.params.projectId }
        }"
      >
        <NavActivity
          :text="project.meta['#']"
          :icon="$t('@author.projects-icon')"
        />
      </b-nav-item>
      <b-nav-text v-if="workspaceItem" class="py-0">
        <v-icon small :class="`text-${textVariant}`">mdi-chevron-right</v-icon>
      </b-nav-text>
      <b-nav-item v-if="workspaceItem"
        :to="{
          name: (workspaceItem
            ? workspaceItem.task || workspaceItem.activity
            : false
          ) || activityOverview,
          params: workspaceItem ? workspaceItem.params : {}
        }"
        class="mr-auto"
      >
        <NavActivity v-if="workspaceItem"
          :text="workspaceItem
            ? workspaceItem.meta
              ? workspaceItem.meta['#']
              : workspaceItem.id
            : ''"
          :icon="$t(`${area}.${workspaceItem
            ? workspaceItem.resource :''
          }-icon`)"
        />
      </b-nav-item>
      <b-nav-item v-if="!route.params.projectId"
        :to="{
          name: `${area}:profile`,
          params: { projectId: route.params.projectId },
        }"
        class="clip-ellipsis"
      >
        <NavActivity :text="$t(greeting)" />
      </b-nav-item>

      <div class="ml-auto"></div>

      <b-nav-item
        v-for="_activity in activities"
        :key="_activity.meta.activity"
        :active="_activity.active"
        :to="{
          name: (
            _activity.name
            || _activity.meta.defaultTask
            || _activity.meta.task
            || _activity.meta.activity
          ),
          params: route.params,
        }"
        :disabled="_activity.lowParams"
        :class="{
          'nav-area cursor-pointer': true,
          'nav-area-active': _activity.active,
          [`bg-${_activity.variant}${dark ? '-dark': ''}`]: _activity.active,
          [`bg-${themeVariant}`]: !_activity.active && !_activity.lowParams,
          [`bg-deep-${dark ? 'dark' : 'light'}`]: (
            !_activity.active && _activity.lowParams
          ),
        }"
        :style="Object.assign(
          {},
          themeStyle('.nav-area'),
          _activity.active
            ? themeStyle('.nav-area-active')
            : {},
        )"
      >
        <NavActivity
          v-bind="Object.assign({}, _activity, {
            component: undefined,
            children: undefined,
          })"
          :key="_activity.meta.activity"
          :disabled="_activity.lowParams"
        />
      </b-nav-item>
      <b-nav-text class="px-0 py-0">
        <b-spinner
          v-if="pluginInstallQueue.length"
          class="position-absolute"
          style="margin-top: 6px;"
          type="grow"
          :variant="currentArea.variant || textVariant"
        ></b-spinner>
        <v-icon v-b-modal.side-settings
          @click="() => null"
          small
          :class="`cursor-pointer mx-2 text-${textVariant}`"
        >mdi-cog</v-icon>
        <v-icon
          small
          :class="`cursor-pointer text-${textVariant} mx-2`"
          v-b-modal.issue-tracker
          @click="() => null"
        >mdi-bug</v-icon>
        <v-icon
          small
          :class="`cursor-pointer text-${textVariant} mx-2`"
          v-b-modal.side-theme-picker
          @click="() => null"
        >mdi-image</v-icon>
        <v-icon
          small
          :class="`mx-2 text-${textVariant} align-middle`"
          @click.stop="setTheme({ variant: dark ? 'light' : 'dark' })"
        >mdi-{{ dark ? 'moon-waning-crescent': 'white-balance-sunny' }}</v-icon>
      </b-nav-text>
      <b-nav-text class="p-0 ml-2" v-b-modal.side-account>
        <span
          v-show="$route.name !== '@account'"
          class="cursor-pointer rounded-circle"
        ><img v-if="user.photoURL"
          :src="user.photoURL"
          width="22"
          class="rounded-circle border border-dark"
        />
        </span>
      </b-nav-text>
    </template>

    <template #content>
      <router-view />

      <b-modal
        id="side-settings"
        no-fade
        hide-backdrop
        header-class="d-none"
        footer-class="d-none"
        :body-bg-variant="`deep-${themeVariant}`"
        :title="$t('settings')"
      >
        <Settings/>
      </b-modal>

      <b-modal
        id="issue-tracker"
        size="lg"
        header-class="d-none"
        cancel-variant="outline-secondary"
        ok-variant="mango"
        :body-bg-variant="`deep-${themeVariant}`"
        :footer-bg-variant="themeVariant"
        :title="$t('issue-tracker')"
      >
        <IssueTracker/>
      </b-modal>

      <b-modal
        id="side-theme-picker"
        lazy
        no-fade
        hide-backdrop
        header-class="d-none"
        footer-class="d-none"
        :body-bg-variant="`deep-${themeVariant}`"
        :title="$t('theme-picker')"
      >
        <ThemeList/>
      </b-modal>

      <b-modal
        id="side-account"
        no-fade
        hide-backdrop
        header-class="d-none"
        footer-class="d-none"
        :body-bg-variant="`deep-${themeVariant}`"
        :title="$t('account')"
      >
        <User/>
      </b-modal>
    </template>

  </WindowLayout>
</template>

<script>
import { get, find, filter } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import { auth } from '@/firebase';
import loadProvider from '@/extensions';
import startServices from '@/services';
import store from '@/store';
import MythuluLogo from '@/svg/mythulu.svg';
import WindowLayout from '@/layouts/WindowLayout.vue';
import ThemeList from './themes/List.vue';
import NavActivity from './NavActivity.vue';
import IssueTracker from './IssueTracker.vue';
import Settings from './Settings.vue';
import User from './User.vue';

import * as bridge from '@/provider/cloud-bridge';

window.cloud = bridge.cloud;
['device', 'account', 'remoteNavigation', 'context'].forEach((key) => {
  if (window[key] === undefined) {
    window[key] = bridge[key];
  }
});

/**
 * Main content component after authentication has passed.
 */
export default {
  queries: {
    /**
     * @name projects
     * @type {Array}
     */
    '@author.projects': 'projects',
  },
  data: () => ({
    /** The current version milestone. */
    version_display: 'MVP',
  }),
  computed: {
    ...mapState([
      'user',
      'route',
      'area',
      'activity',
      'activityOverview',
      'pluginInstallQueue',
    ]),
    ...mapState({
      connected: state => get(state, '@account.ready'),
      name: state => get(state, '@account.name'),
      navAreas: (state, { areas }) =>
        filter(areas, a => a.name !== '@account'),
      activities: ({ area }, { getAreaActivities }) =>
        getAreaActivities(area).filter(
          _activity => !_activity.shy || _activity.active
        ),
      project({ route }) {
        return (
          find(this.projects, { id: route.params.projectId })
            || { meta: { '#': route.params.projectId }}
        );
      },
    }),
    ...mapGetters([
      'currentArea',
      'areaVariant',
      'activityVariant',
      'theme',
      'themeStyle',
      'themeVariant',
      'textVariant',
      'dark',
      'editorFullscreen',
      'workspaceItem',
    ]),

    /**
     * Greeting list for proportion of a 24-hour clock divided by the number of
     * entries.  Four entries creates four 6-hour blocks starting at midnight.
     * @type {function}
     * @property {string} 0
     */
    greeting: () => ({
      0: 'greeting-midnight', // midnight-6am
      1: 'greeting-am',  // 6am-12pm
      2: 'greeting-afternoon',  // 12pm-6pm
      3: 'greeting-pm',  // 6pm-midnight
    })[parseInt(new Date().getHours() / 6)],
  },
  methods: {
    ...mapActions([
      'query',
      'queuePluginInstall',
      'setTheme',
    ]),
    logOut() {
      auth.signOut().then(() => this.$router.replace({ name: 'home' }));
    },
  },
  components: {
    /** Mythulu logo SVG. */
    MythuluLogo,
    /** Layout controller. */
    WindowLayout,
    /** Activity display, powered by `$route`. */
    NavActivity,
    /** IssueTracker modal content. */
    IssueTracker,
    /** Settings modal content. */
    Settings,
    /** Theme list modal content. */
    ThemeList,
    /** User modal content. */
    User,
  },

  /**
   * Starts services, loads avilable providers.  This method is also responsible
   * for hooking the plugin install deep-link, as well as checking the
   * first-launch WelcomeWindow.
   */
  created() {
    startServices();
    store.getters.providerOrder.map(loadProvider);

    window.device.welcome();
    window.device.checkDependencies();

    if (window.remoteNavigation) {
      window.remoteNavigation.install((pluginUrl) => {
        console.log('🔌 queue', pluginUrl);
        this.queuePluginInstall(pluginUrl);
      });
    }

    window.context.listen((action, item) => {
      const { params={} } = item;
      switch (action) {
        case 'view':
          this.$router
            .push({ name: this.task, params })
            .catch(console.warn)
            ;
          break;
        case 'destroy':
          this.query({
            force: true,
            resource: `${this.area}.destroyItems`,
            query: {
              projectId: item.projectId,
              type: item.type,
              params: item.params,
            },
          });
          break;
        case 'scrap':
          this.query({
            force: true,
            resource: `${this.area}.scrapItems`,
            query: Object.assign({}, item, {
              target: item.params.projectId,
            }),
          });
          break;
        case 'scrap-to':
          this.query({
            force: true,
            resource: `${this.area}.scrapItems`,
            query: Object.assign({}, item, {
              target: item.targetProjectId,
            }),
          });
          break;
        default:
          console.warn('Unknown context action', action, params);
      }
    });
  },
};
</script>
