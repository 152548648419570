import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-nav',{class:{
    [`nav-tasks rounded-top px-0 bg-${_vm.themeVariant}`]: true,
  },style:(_vm.themeStyle('.rounded-top')),attrs:{"pills":""}},[_vm._l((_vm.children),function(_task){return _c('b-nav-item',{key:_task.name,class:{
      'nav-task p-0': true,
      [`nav-task-active bg-${_vm.activityVariant}`]: _vm.isActive(_task),
      'd-none': _task.hidden || _task.shy && !_vm.isActive(_task),
      'opacity-1': _task.disabled,
    },style:(Object.assign({},
      _vm.themeStyle('.nav-task'),
      _vm.isActive(_task) ? _vm.themeStyle('.nav-task-active') : {},
    )),attrs:{"to":_vm.route.params.projectId
      ? { name: _task.name, params: _vm.route.params }
      : undefined,"disabled":_task.disabled || !_vm.route.params.projectId && !_task.shy,"active":_vm.isActive(_task),"variant":_vm.activityVariant}},[(_task.icon || _task.meta)?_c('span',{class:_vm.isActive(_task) ? 'text-light' : `text-${_vm.textVariant}`},[(_task.showIcon && _task.icon)?_c(VIcon,{class:{
          'mr-2': true,
          [`text-light`]: _vm.isActive(_task),
        },attrs:{"dark":_vm.dark && !_vm.isActive(_vm.task)}},[_vm._v(_vm._s(_task.icon))]):_vm._e(),(_task.meta && _task.showLabel !== false)?_c('span',[_vm._v(" "+_vm._s(_vm.$t(_task.meta.task))+" ")]):_vm._e()],1):_vm._e()])}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }