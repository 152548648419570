<template>
  <div>
    <h6 class="display-5 text-center mt-3" v-show="folds[idParam]">Related</h6>
    <span class="help-message">{{ $t('nav-column-hierarchy-help') }}</span>
    <b-list-group class="rounded-0">
      <b-list-group-item
        v-for="(enabled, subSource) in folds[idParam]"
        :key="`${source}-${idParam}-${subSource}`"
        :variant="enabled ? activityVariant : themeVariant"
        :class="`p-1 cursor-pointer ${enabled ? 'strong' : ''}`"
        @click.stop="toggleSubSource(subSource, !enabled)"
      >
        <v-icon
          small
          :class="`text-${enabled ? activityVariant : 'muted'}`"
        >{{ $t(`${subSource}-icon`) }}</v-icon>
        {{ $t(subSource) }}
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import Draggable from 'vuedraggable'

/**
 * Displays a pre-folded list of data sources by a target attribute.
 * Sub-sources are related data types that collapse under common values of
 * `idParam` on the item list.
 *
 * <Demo-HierarchyPicker />
 */
export default {
  props: {
    /** Target workspace nav name, e.g., `main`, `secondary`, `pinned`. */
    nav: String,

    /** Primary source type, used for `:key` uniqueness. */
    source: String,

    /**
     * The mapping of possible primary attribute filters to the Array of that
     * grouping.
     */
    folds: { type: Object, default: () => ({}), optional: true },

    /** Name in `folds` to use as main display list. */
    idParam: String,
  },

  /**
   * @type {object}
   * @property {string} themeVariant
   * @property {string} activityVariant
   */
  computed: {
    ...mapGetters([
      'themeVariant',
      'activityVariant',
    ]),
  },
  methods: {
    ...mapActions([
      'configureNav',
    ]),
    toggleSubSource(subSource, enabled) {
      this.configureNav({
        name: this.nav,
        folds: { [this.idParam]: { [subSource]: enabled } },
      });
    },
  },
}
</script>
