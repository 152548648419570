<template>
  <div
    :source="source"
    :class="{
      'flex-grow-1': !parent,
    }"
    :style="!parent
      ? { 'overflow-y': 'auto' }
      : {}
    "
  >
      <!-- 'min-height': parent ? 'auto' : height, -->
    <Draggable
      tag="b-list-group"
      class="border-0 rounded-0"
      ghost-class="placeholder-from"
      v-model="items"
      :group="`${nav}-${source}`"
      filter=".no-drag"
      :prevent-on-filter="false"
      flush
      animation
      :disabled="false"
    >
      <transition-group type="transition" name="flip-list">
        <b-list-group-item
          v-for="(item, i) in items"
          :key="`${parent}-${parentId}-${source}-${item.id}`"
          :to="undefined /* hack breaks hover/focus, but stops inner input click bug */"
          v-scroll-to="{
            el: item.multiDisplay
              ? `#${item.safeId || `${item.type}-${item.id || i}`}`
              : undefined,
            container: scrollFrame,
          }"
          :class="{
            'rounded-0 border-0 px-0': true,
            'py-1': !parent,
            'py-0': parent,
            'delegated-group': navSpec.delegate[source],
            [`cursor-pointer bg-transparent text-${textVariant}`]: !selected.includes(item.id),
            [`cursor-default bg-${variant} text-light`]: selected.includes(item.id),
          }"
          @click.stop="select(item)"
          @contextmenu.stop="setContext({ scope: 'navigator', item })"
        >
          <Item
            :id="item.id"
            :data="item"
            :source="source"
            :tag-id="list ? parentId : null"
            :tag-type="list ? parentType : null"
            :tag-path="list ? source : null"
            :columns="processColumns(parent, source)"
            :variant="variant"
            :parent-active="parentActive"
            :active="selected.includes(item.id)"
            :hits="item.hits"
            :delegated="navSpec.delegate[source]"
            :delegated-by="delegatedBy"

            :small="!list && Boolean(parent)"
            :icon="parent && $t(`${source}-icon`)"
            :icon-variant="dark && getItemVariant(item) || 'light'"
          />
          <List
            v-for="(ids, subSource) in (joins[item.id] || {})"
            :key="`${parent}-${parentId}-${item.id}-${subSource}`"

            :nav="nav"
            :source="subSource"
            :template="getEmbeddedRoot(source, item.id, subSource).template"
            :list="findEmbeddedList(item, subSource)"
            :delegated-by="getEmbeddedListName(item, source)"
            :columns="processColumns(source, subSource)"
            :filter="filter"
            :joins="joins"
            :parent="source"
            :parent-type="item.type"
            :parent-id="item.id"
            :parent-active="selected.includes(item.id)"
            :variant="variant"
            :scroll-frame="scrollFrame"
          />
        </b-list-group-item>
      </transition-group>
    </Draggable>
  </div>
</template>

<script>
import { get, find, filter, orderBy, differenceBy, escapeRegExp } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import Draggable from 'vuedraggable'
import Item from './Item.vue';

export default {
  name: 'List',
  props: {
    nav: String,
    source: String,
    columns: Array,
    // selected: Array,
    scope: { type: Object, default: () => ({}), option: true },
    filter: String,
    filterResults: Boolean,
    flex: { type: Boolean, default: true },
    variant: { type: String, default: 'primary', optional: true },
    joins: { type : Object, default: () => ({}), optional: true },
    parent: { type: String, default: null, optional: true },
    parentType: { type: String, default: null, optional: true },
    parentId: { type: String, default: null, optional: true },
    parentActive: { type: Boolean, default: null, optional: true },
    template: { type: Object, default: () => ({}), optional: true },
    list: { type: Array, default: null, optional: true },
    delegatedBy: { type: String, default: null, optional: true },
    scrollFrame: { type: String, default: 'body' },
  },
  computed: {
    ...mapState([
      'route',
      'area',
      'phase',
    ]),
    ...mapGetters([
      'dark',
      'textVariant',
      'themeVariant',
      'getAreaActivities',
      'workspace',
      'workspaceItem',
      'getEmbeddedRoot',
      'getEmbeddedList',
      'getTag',
    ]),
    ...mapState({
      navSpec({}, { nav }) {
        return nav[this.nav];
      },
    }),
    selected() {
      return [this.workspace.location.params[
        this.navSpec.selected[this.source]
      ]];
    },
    // isSortedByOrder() {
    //   // TODO: Deal with completion sorting
    //   return this.columns.filter(col => (
    //     col.id === this.counter
    //     && col.sort === 'asc'
    //   )).length > 0;
    // },
    items: {
      get() {
        return this.getItems(
          this.route.params.projectId,
          this.source,
          this.filter,
        );
      },
      set(newList) {
        const orderOf = item => [
          get(item, this.getCounter(this.source).split('.')) || '0',

          // If a virtual list is given, the parentId is the primary discovery
          // id, but the item.id is the virtual item inside it.  Both will be
          // required in the backend to resolve the item and full tag path.
          `${this.list ? `${this.parentId}///` : ''}${item.id}`,
        ];
        const oldOrder = this.items.map(orderOf);
        const newOrder = newList.map(orderOf);
        let movedItem = null;

        if (oldOrder.length < newOrder.length) {
          // Item was moved into this new parent group.
          // The item with mismatching `params` is the moved item, and will
          // enable us to handle the removal from the old list in the plugin
          // service.
          [ movedItem ] = differenceBy(newList, this.items, ({ id }) => id);
        } else if (oldOrder.length > newOrder.length) {
          // Item was moved out of this old parent group.
          // This call to set() will occur for the parent group that lost the
          // item, but we ignore it since we have the full item spec in the
          // above condition, complete with location params and attached data.
          // We want there to be only one atomic call to exchangeTags() so that
          // it is undo-able.
          return;
        }

        /**
         * Map the dislocated items to [oldOrder, newOrder, id]
         */
        const orderInfo = newOrder
          .map(([order, id], i) => [order, (oldOrder[i] || [null])[0], id])
          ;

        // Early check for edge case where displaced items have the same order
        // value and will appear not to have changed to a new value, i.e., when
        // a 3 is next to a 3, and a new item inserted above, the first 3 will
        // take the second 3's place, but since 3 === 3, it will appear
        // unchanged when we filter away items that haven't been modified.
        let offset = 0;
        const used = { 0: true };  // 0 forces missing tags to change offset
        orderInfo.forEach(([o1, o2, id], i) => {
          const v = parseInt(o2);
          if (used[parseInt(v)]) {  // this may need to become a while loop
            // Order value is already used, so increment artificial offset.
            offset++;
            orderInfo[i][1] = `${v + offset}`;
          } else {
            used[v] = true;
          }
        });

        // Filter away unchanged items.
        const altered = orderInfo.filter(([o1, o2]) => o1 !== o2)

        if (movedItem) {
          // Moving an item into the list means that the re-flowed order will
          // leave the last item with a `null` order. We'll fill it with 1
          // greater than the previous oldOrder.  This works even if if the new
          // item was moved to the end of a list (thus displacing no items).
          altered.slice(-1)[0][1] = `${parseInt(oldOrder.slice(-1)[0]) + 1}`;
        }
        this.query({
          force: true,
          resource: `${this.area}.exchangeTags`,
          query: {
            projectId: this.$route.params.projectId,
            type: this.list
              ? this.parentType
              : newList[0].type,
            updates: altered,
            tagPrefix: this.list
              ? `meta.${this.source}.${this.delegatedBy}`
              : null,
            tag: this.getCounter(this.source),
            annotator: this.getAnnotator(this.source),  // possibly undefined
            // freeze: info.freeze,  // defaults to ['id'] in services
          },
        });
      },
    },
  },
  methods: {
    ...mapActions([
      'query',
    ]),
    getCounter(source) {
      return this.navSpec.counters[source] || 'meta.order';
    },
    getParentCounter(parent) {
      return this.navSpec.counters[parent] || 'meta.order';
    },
    getEmbeddedScope(item) {
      const scopeParam = this.navSpec.scope[this.source];
      return Object.assign(
        {},
        this.scope,
        scopeParam
          ? { [scopeParam]: this.getTag(item, scopeParam, {}) }
          : {},
      );
    },
    getEmbeddedListName(item, source) {
      const listTagPath = this.navSpec.delegate[source];
      if (!listTagPath) {
        return null;
      }
      return this.getTag(item, this.getTag(item, listTagPath), this.phase);
    },
    findEmbeddedList(item, subSource) {
      const name = this.getEmbeddedListName(item, this.source);
      if (!name) {
        return null;
      }
      return this.getEmbeddedList(
        this.source, item.id, `${subSource}.${name}`,
        this.getEmbeddedScope(item)
      );
    },
    /**
     * Re-process parent's counter column to the local counter column.
     * @return {Object}
     */
    processColumns(parent, source) {
      return this.columns.map(col => {
        const parentTag = parent ? this.getParentCounter(parent) : undefined;
        if (col.id === parentTag) {
          return Object.assign({}, col, {
            sort: 'asc',  // force sub-source to stay sorted naturally
            id: this.getCounter(source),
            annotator: this.getAnnotator(source),  // possibly undefined
          });
        }
        return (
          (this.navSpec.columns[source] || []).find(c => c.id === col.id)
          || col
        );
      });
    },
    getAnnotator(source) {
      return this.navSpec.annotators[source];  // possibly undefined
    },
    getItemVariant({ area, activity}) {
      const { variant } = (
        find(this.getAreaActivities(area),{ meta: { activity } })
        || { variant: this.textVariant }
      );
      return variant;
    },
    /**
     * Filters a query cache list with optional filter and sort.
     * @argument {String} projectId
     * @argument {String} source `@areaname.sourcename` string
     * @return {Object[]} Sorted array of items
     */
    getItems(projectId, source) {
      let items = [];
      if (!this.list) {
        const [ area ] = this.source.split('.', 1);
        const ids = this.parent
          ? (this.joins[this.parentId] || {})[source]
          : undefined
          ;
        items.push(...[].concat(this.$store.getters[`${area}/getQuery`](
          source, projectId, ids
        ) || []));
      } else {
        items.push(...this.list);
      }

      if (items.loading) {
        return [];
      }

      let pattern = null;
      items = filter(items, this.scope);

      // Convert user filter string to regex.
      if (this.filter.trim().length) {
        if (this.filter.startsWith('/')) {
          try {
            pattern = new RegExp(this.filter.substring(1));
          } catch (e) {
            console.log(this.nav, e);
          }
        } else {
          pattern = new RegExp(`${escapeRegExp(this.filter)}`, 'i');
        }
      }

      // Create `hits` prop on each item that indicates which data column
      // matches the filter regex.
      if (pattern) {
        // Map item to visible column values and filter for `pattern` matches.
        const hits = items.map(
          (item, i) => this.columns
            .map(col => [col.id, get(item, col.id.split('.'))])
            .filter(
              ([k, v]) => (v === null || v === undefined)
                ? false
                : pattern.test(`${v}`)
            )
        );

        // Assign hits to original item as a map of column hits
        items.forEach((item, i) => {
          item.hits = hits[i].length
            ? Object.assign({}, ...hits[i].map(([k, v]) => ({ [k]: v })))
            : undefined
        });

        // When filterResults is on, remove items lacking hits.
        if (this.filterResults) {
          items = items.filter(item => item.hits);
        }
        // console.log(this.nav, pattern, hits, items.map(i => i.hits));
      } else {
        items.forEach((item) => {
          item.hits = undefined;
        });
      }

      return orderBy(
        items,
        filter(this.columns, 'sort').map(col => col.id),
        filter(this.columns, 'sort').map(col => col.sort)
      );
    },
    select(item) {
      const hasWorkspaceItem = this.workspaceItem !== undefined;
      const isOtherItem = !hasWorkspaceItem
        || item.id !== this.workspaceItem.id;
      if (item.task && isOtherItem) {
        this.$store.dispatch(`${this.area}/setLocation`, item);
        this.$router.push({
          name: (item.task || this.task),
          params: item.params,
        });
      }
    },
    newItem(metaBase, item=undefined) {
      const template = (
        item ? item.template : this.template
      ) || { '#': '' };
      const newItem = Object.assign({}, template, metaBase);
      console.log(newItem);
    },
    setContext: (...args) => window.context.set(...args),
  },
  components: {
    Draggable,
    Item,
  },
};
</script>
