<template>
  <div :class="`auth-icon ${selected ? '': 'auth-icon-other'}`">
    <v-icon x-large :class="`text-${textVariant}`"
    >mdi-{{ icons[provider] }}</v-icon>
    <div v-if="showName">
      <small :class="`text-${textVariant}`">{{ provider }}</small>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { icons } from '@/firebase';

export default {
  props: {
    provider: { type: String },
    selected: { type: Boolean },
    showName: { type: Boolean, default: true },
  },
  data: () => ({
    icons,
  }),
  computed: {
    ...mapGetters([
      'textVariant',
    ]),
  }
};
</script>
