import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
  [`editor zoom-${_vm.editorSize}`]: true,
  justify: _vm.editorJustify,
}},[_c('EditorFloatingMenu',{attrs:{"editor":_vm.editor(_vm.item)},scopedSlots:_vm._u([{key:"default",fn:function({ commands, isActive, menu }){return [_c('div',{class:{
        'inline-menu position-absolute': true,
        'invisible': !menu.isActive,
        'visible active': menu.isActive,
      },style:(`top: ${menu.top + _vm.offset() - 4}px;`)},[(_vm.sceneControls)?_c(VIcon,{class:`mx-1 text-${_vm.textVariant}`,attrs:{"small":""},on:{"click":function($event){return commands.horizontal_rule()}}},[_vm._v("mdi-format-page-break")]):_vm._e(),(_vm.sceneControls)?_c(VIcon,{class:{
          [`mx-1 text-${_vm.textVariant}`]: true,
          'active': isActive.heading({ level: 2 }),
        },attrs:{"small":""},on:{"click":function($event){return commands.heading({ level: 2 })}}},[_vm._v("mdi-format-header-1")]):_vm._e(),_c(VIcon,{class:{
          [`mx-1 text-${_vm.textVariant}`]: true,
          'active': isActive.bullet_list(),
        },attrs:{"small":""},on:{"click":function($event){return commands.bullet_list()}}},[_vm._v("mdi-format-list-bulleted-square")]),(_vm.sceneControls)?_c(VIcon,{class:{
          [`mx-1 text-${_vm.textVariant}`]: true,
          'active': isActive.blockquote(),
        },attrs:{"small":""},on:{"click":function($event){return commands.blockquote()}}},[_vm._v("mdi-format-indent-increase")]):_vm._e()],1)]}}])}),_c('div',{class:{
    [_vm.formatType]: true,
    'gutters': _vm.sceneControls,
  }},[_c('EditorContent',{attrs:{"editor":_vm.editor(_vm.item),"spellcheck":true},on:{"contextmenu":function($event){return _vm.setContext({ scope: 'editor' })}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }