<template>
  <div>
    <TitleBar />
    <div
      id="onboarding"
      :theme="dark ? 'dark' : 'light'"
      :class="`mx-auto text-${textVariant}`"
    >
      <div class="text-center">
        <span id="logo"><MythuluLogo /></span><span id="dot-ink" style="font-size: 40px; position: relative; bottom: -11px;"
        >.ink</span>
      </div>

      <OnboardingSignIn v-if="!user" />
      <OnboardingUser v-else
        :relay-auth-to-electron="relayAuthToElectron"
        :credential="credential"
      />

      <OnboardingShare v-if="user && !relayAuthToElectron" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { signInWithCredential } from 'firebase/auth';
import { auth, providers } from '@/firebase';
import { recoverToken } from '@/token';
import MythuluLogo from '@/svg/mythulu.svg';
import TitleBar from './TitleBar.vue';
import OnboardingSignIn from './onboarding/SignIn';
import OnboardingShare from './onboarding/Share';
import OnboardingUser from './onboarding/User';

export default {
  props: {
    relayAuthToElectron: { type: Boolean, optional: true, default: false },
  },
  data: () => ({
    credential: null,
  }),
  computed: {
    ...mapState([
      'user',
      'ready',
    ]),
    ...mapGetters([
      'dark',
      'textVariant',
    ]),
    isWeb: () => window.device.isWeb(),
  },
  methods: {
    ...mapActions([
      'setAuthIssue',
    ]),
  },
  components: {
    TitleBar,
    MythuluLogo,
    OnboardingSignIn,
    OnboardingUser,
    OnboardingShare,
  },
  created() {
    if (this.isWeb) {
      recoverToken().then((credential) => {
        this.credential = credential;
      })
    } else {
      window.remoteNavigation.auth((providerId, credential) => {
        signInWithCredential(auth, providers[providerId].credential(credential))
          .catch(this.setAuthIssue)
          ;
      });
    }
  },
}
</script>

<style lang="scss">
#onboarding {
  width: 400px;
  margin-top: 70px;
  font-family: Georgia;

  &[theme="light"] {
    #logo {
      filter: invert(19%) sepia(0%) saturate(0%) hue-rotate(22deg) brightness(97%) contrast(89%);
    }
    #dot-ink {
      color: #303030;
    }
  }
  &[theme="dark"] {
    #logo {
      filter: invert(100%) sepia(0%) saturate(1012%) hue-rotate(43deg) brightness(115%) contrast(75%);
    }
    #dot-ink {
      color: #a0a0a0;
    }
  }

  #logo {
    height: 90px;
    // line-height: 50px;

    svg {
      height: 90px;
    }
  }
}
</style>
