<template>
  <b-nav pills
    :class="{
      [`nav-tasks rounded-top px-0 bg-${themeVariant}`]: true,
    }"
    :style="themeStyle('.rounded-top')"
  >
    <b-nav-item
      v-for="_task in children"
      :key="_task.name"
      :to="route.params.projectId
        ? { name: _task.name, params: route.params }
        : undefined
      "
      :disabled="_task.disabled || !route.params.projectId && !_task.shy"
      :active="isActive(_task)"
      :variant="activityVariant"
      :class="{
        'nav-task p-0': true,
        [`nav-task-active bg-${activityVariant}`]: isActive(_task),
        'd-none': _task.hidden || _task.shy && !isActive(_task),
        'opacity-1': _task.disabled,
      }
      "
      :style="Object.assign({},
        themeStyle('.nav-task'),
        isActive(_task) ? themeStyle('.nav-task-active') : {},
      )"
    >
      <span
        v-if="_task.icon || _task.meta"
        :class="isActive(_task) ? 'text-light' : `text-${textVariant}`">
        <v-icon
          v-if="_task.showIcon && _task.icon"
          :class="{
            'mr-2': true,
            [`text-light`]: isActive(_task),
          }"
          :dark="dark && !isActive(task)"
        >{{ _task.icon }}</v-icon>
        <span v-if="_task.meta && _task.showLabel !== false">
          {{ $t(_task.meta.task) }}
        </span>
      </span>
    </b-nav-item>

    <slot class="ml-auto"></slot>
  </b-nav>
</template>

<script>
import { get } from 'lodash';
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    children: { type: Array, default: () => [], optional: true },
  },
  computed: {
    ...mapState([
      'route',
      'area',
      'task',
    ]),
    ...mapGetters([
      'dark',
      'textVariant',
      'themeVariant',
      'themeStyle',
      'activityVariant',
      'getActivityTasks',
    ]),
  },
  methods: {
    isActive(task) {
      return this.task === get(task, 'meta.task');
    },
  },
}
</script>
