<template>
  <div :class="`px-2 cursor-default text-${textVariant}`">
    <div class="w-100 text-right mt-2 mb-3" v-for="info in user.providerData" :key="info.uid">
      {{ user.email }}
      <v-icon :dark="dark" class="ml-2">mdi-{{ accountIcons[info.providerId] }}</v-icon>
    </div>
    <h5 :class="{
      'display-5 text-center': true,
      'text-muted': !user.displayName,
    }">{{ user.displayName || 'Name' }}</h5>
    <div class="w-100 text-center">
      <a @click.prevent="doLogOut" class="cursor-pointer underline-0">
        <v-icon :dark="dark" class="mr-2">mdi-logout-variant</v-icon>
        Sign out
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'User',
  data: () => ({
    accountIcons: {
      email: 'email',
      'github.com': 'github',
      'facebook.com': 'facebook',
      'google.com': 'google',
      'twitter.com': 'twitter',
      anonymous: 'incognito',
    },
  }),
  computed: {
    ...mapState([
      'user',
    ]),
    ...mapGetters([
      'dark',
      'themeVariant',
      'textVariant',
    ]),
  },
  methods: {
    ...mapActions([
      'logOut',
    ]),
    doLogOut() {
      this.$router.replace({ name: 'home' });
      this.logOut();
    },
  }
};
</script>
