import * as navFlat from './utils/nav-app';
import * as navSlanted from './utils/nav-slanted';
import * as logo from './utils/logo';

export default {
  /* Flat-type themes */
  Plain: {
    label: 'Plain',
    order: 1,
    variants: {
      light: {
        order: 1,
        dark: false,
        textVariant: 'dark',
        styles: {
          ...logo.dark,
          ...navFlat.light,
          '#window': {
            'background-color': 'var(--deep-light)',
          },
        },
      },
      dark: {
        order: 2,
        dark: true,
        textVariant: 'light',
        styles: {
          ...logo.light,
          ...navFlat.dark,
          '#window': {
            'background-color': 'var(--deep-dark)',
          },
        },
      },
    },
  },

  /* Image-type themes */
  CloudCity: {
    label: 'Cloud City',
    order: 2,
    variants: {
      light: {
        order: 1,
        dark: false,
        textVariant: 'dark',
        styles: {
          ...logo.dark,
          ...navSlanted.light,
          '#window': {
            background: 'no-repeat center/100% url(/i/bg-light.jpg) !important',
          },
        },
      },
    },
  },
  Pitch: {
    label: 'Pitch',
    order: 3,
    variants: {
      dark: {
        order: 1,
        dark: true,
        textVariant: 'light',
        styles: {
          ...logo.light,
          ...navSlanted.dark,
          '#window': {
            background: 'no-repeat center/100% url(/i/bg-dark.jpg) !important',
          },
        },
      },
    },
  },
  Forest: {
    label: 'Forest',
    order: 4,
    variants: {
      light: {
        order: 1,
        dark: false,
        textVariant: 'dark',
        styles: {
          ...logo.dark,
          ...navSlanted.light,
          '#window': {
            background: 'no-repeat center/100% url(/i/bg-forest.jpg) !important',
          },
          '#ink-area': {
            'text-shadow': '0 1px rgba(0,0,0,0.5), -1px 1px rgba(0,0,0,0.5)',
          },
        },
      },
      dark: {
        order: 2,
        dark: true,
        textVariant: 'light',
        styles: {
          ...logo.dark,
          ...navSlanted.dark,
          '#window': {
            background: 'no-repeat center/100% url(/i/bg-forest.jpg) !important',
          },
          '#ink-area': {
            'text-shadow': '0 1px rgba(0,0,0,0.5), -1px 1px rgba(0,0,0,0.5)',
          },
        },
      },
    },
  },
  Hills: {
    label: 'Hills',
    order: 5,
    variants: {
      light: {
        order: 1,
        dark: false,
        textVariant: 'dark',
        styles: {
          ...logo.dark,
          ...navSlanted.light,
          '#window': {
            background: 'no-repeat center/100% url(/i/bg-hills.jpg) !important',
          },
          '#ink-area': {
            'text-shadow': '0 1px rgba(0,0,0,0.5), -1px 1px rgba(0,0,0,0.5)',
          },
        },
      },
      dark: {
        order: 2,
        dark: true,
        textVariant: 'light',
        styles: {
          ...logo.dark,
          ...navSlanted.dark,
          '#window': {
            background: 'no-repeat center/100% url(/i/bg-hills.jpg) !important',
          },
          '#ink-area': {
            'text-shadow': '0 1px rgba(0,0,0,0.5), -1px 1px rgba(0,0,0,0.5)',
          },
        },
      },
    },
  },
  Lake: {
    label: 'Lake',
    order: 6,
    variants: {
      light: {
        order: 1,
        dark: false,
        textVariant: 'dark',
        styles: {
          ...logo.light,
          ...navSlanted.light,
          '#window': {
            background: 'no-repeat center/100% url(/i/bg-lake.jpg) !important',
          },
          '#ink-area': {
            'text-shadow': '0 1px rgba(0,0,0,0.5), -1px 1px rgba(0,0,0,0.5)',
          },
        },
      },
      dark: {
        order: 2,
        dark: true,
        textVariant: 'light',
        styles: {
          ...logo.light,
          ...navSlanted.dark,
          '#window': {
            background: 'no-repeat center/100% url(/i/bg-lake.jpg) !important',
          },
          '#ink-area': {
            'text-shadow': '0 1px rgba(0,0,0,0.5), -1px 1px rgba(0,0,0,0.5)',
          },
        },
      },
    },
  },
  Firefly: {
    label: 'Firefly',
    order: 7,
    variants: {
      light: {
        order: 1,
        dark: false,
        textVariant: 'dark',
        styles: {
          ...logo.light,
          ...navSlanted.light,
          '#window': {
            background: 'no-repeat center/100% url(/i/bg-firefly.jpg) !important',
          },
        },
      },
      dark: {
        order: 2,
        dark: true,
        textVariant: 'light',
        styles: {
          ...logo.light,
          ...navSlanted.dark,
          '#window': {
            background: 'no-repeat center/100% url(/i/bg-firefly.jpg) !important',
          },
        },
      },
    },
  },
  Snow: {
    label: 'Snow',
    order: 8,
    variants: {
      light: {
        order: 1,
        dark: false,
        textVariant: 'dark',
        styles: {
          ...logo.dark,
          ...navSlanted.light,
          '#window': {
            background: 'no-repeat center/100% url(/i/bg-snow.jpg) !important',
          },
        },
      },
      dark: {
        order: 2,
        dark: true,
        textVariant: 'light',
        styles: {
          ...logo.dark,
          ...navSlanted.dark,
          '#window': {
            background: 'no-repeat center/100% url(/i/bg-snow.jpg) !important',
          },
        },
      },
    },
  },
};
