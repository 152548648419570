import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import vuetify from './vuetify';
import ServiceEvents from './events';
import VueHotkey from 'v-hotkey';
import VCalendar from 'v-calendar';
import VueScrollTo from 'vue-scrollto';
import './firebase'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './style.scss';
// import './registerServiceWorker';

import NavContentSidebar from './layouts/NavContentSidebar.vue';
import HeaderContent from './layouts/HeaderContent.vue';
import NavTasks from './components/NavTasks.vue';
import Navigator from './components/navigator/Navigator.vue';
import Widget from './components/Widget.vue';
import Editor from './components/Editor.vue';
import Calendar from './components/Calendar.vue';

Vue.use(BootstrapVue);
Vue.use(ServiceEvents);
Vue.use(VueHotkey);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});
Vue.use(VueScrollTo, { duration: 300 });
Vue.config.productionTip = false;

Vue.component('NavContentSidebar', NavContentSidebar);
Vue.component('HeaderContent', HeaderContent);
Vue.component('NavTasks', NavTasks);
Vue.component('Navigator', Navigator);
Vue.component('Widget', Widget);
Vue.component('Editor', Editor);
Vue.component('Calendar', Calendar);

/**
 * Vue Root
 * Mounts `<App/>` to #app
 */
new Vue({
  i18n,
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app');
