export default {
  cloud: {
    env: {
      PROVIDER_ID: null,
      PROVIDER_UUID: null,
      CLIENT_ID: null,
    },
    ink: {
      host: 'functions://',
      firebase: {
        projectId: 'mythulu-ink',
        appId: '1:589983817645:web:a3db4be9995dd4f76c0b24',
        apiKey: 'AIzaSyBBhr2P2SPndT2xGl8CAO-BjovQ8sYy70w',
        authDomain: 'mythulu-ink.firebaseapp.com',
        messagingSenderId: '589983817645',
        measurementId: 'G-R3MMK160R5',
        databaseURL: 'https://mythulu-inc.firebaseio.com',
        // storageBucket: 'mythulu-ink.appspot.com',
      },
    },
    '@agent': {
      host: 'functions://',
      source: 'https://marketplace.mythulu.com/service-author',
      firebase: {
        projectId: 'mythulu-ink',
        appId: '1:589983817645:web:a3db4be9995dd4f76c0b24',
        apiKey: 'AIzaSyBBhr2P2SPndT2xGl8CAO-BjovQ8sYy70w',
        authDomain: 'mythulu-ink.firebaseapp.com',
        messagingSenderId: '589983817645',
        measurementId: 'G-R3MMK160R5',
        databaseURL: 'https://mythulu-inc.firebaseio.com',
        // storageBucket: 'mythulu-ink.appspot.com',
      },
    },
  },
};
