import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{class:Object.assign(
    {
      'navigator-item': true,
      'no-drag': _vm.delegated,
      delegated: _vm.delegated,
    },
    _vm.dataClasses
  ),attrs:{"no-gutters":""}},_vm._l((_vm.visibleColumns),function(col){return _c('b-col',{key:`${_vm.id}-${col.id}`,class:Object.assign({
      'nav-cell': true,
      'nav-cell-with-icon': _vm.icon && col.id === 'meta.#',
      'small': _vm.small,
      [`bg-${_vm.variant}`]: _vm.hasHit(col),
      'text-light': _vm.hasHit(col) || _vm.parentActive || _vm.active,
      'text-left pl-1': col.align === 'left',
      'text-center': col.align === 'center',
      'text-right pr-1': col.align === 'right',
    }),attrs:{"cols":col.width}},[(_vm.icon && col.icon !== false && col.id === 'meta.#')?_c(VIcon,{class:`text-${(_vm.parentActive || _vm.active) ? _vm.iconVariant : _vm.textVariant}`,staticStyle:{"margin-top":"-3px"},attrs:{"small":_vm.small}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),(
        col.type !== null
          && typeof col.hoverable !== 'string'
          && (col.permanentWidget || _vm.edits[col.id] !== undefined))?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[(col.permanentWidget && (
          (col.type || typeof _vm.value(col)) === 'boolean'
        ))?_c('b-form-checkbox',{staticClass:"no-drag",attrs:{"size":col.small ? 'sm' : null,"checked":_vm.value(col)},on:{"change":function($event){return _vm.commitEdit(col, $event)}}}):(col.type === 'icon')?_c('span',{class:{ 'hoverable-reveal': Boolean(col.hoverable) }},[(!col.component)?_c(VIcon,{attrs:{"dark":_vm.dark,"small":col.small}},[_vm._v(_vm._s(col.permanentWidget))]):_c('div',[_c(VIcon,{attrs:{"dark":_vm.dark,"small":col.small},on:{"click":function($event){return _vm.triggerColumn(col)}}},[_vm._v(_vm._s(col.permanentWidget))]),_c(col.component,{tag:"component",attrs:{"id":`${_vm.source}-${_vm.id}-${col.id}-${col.component}`,"data":_vm.data,"columns":_vm.columns,"trigger":_vm.triggers[col.id],"tag-id":_vm.tagId,"tag-type":_vm.tagType,"tag-path":_vm.tagPath,"delegated":_vm.delegated,"delegated-by":_vm.delegatedBy}})],1)],1):_c('b-form-input',{class:{
          [`d-inline-block text-${_vm.textVariant} no-drag cell-input`]: true,
          [`bg-deep-dark`]: _vm.dark,
          [`bg-deep-light`]: !_vm.dark,
          'small mt-1': col.small,
          'mt-0': !col.small,
        },attrs:{"autofocus":"","size":"sm","autocomplete":"off","value":_vm.edits[col.id]},on:{"input":function($event){return _vm.updateEdit(col, $event)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.clearEdit(col)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.commitEdit(col)}],"blur":function($event){return _vm.commitEdit(col)}}})],1):(col.hoverable || col.type !== null)?_c('span',{class:{ strong: _vm.active, small: col.small },on:{"dblclick":function($event){return _vm.startEdit(col)}}},[(col.percent && !_vm.delegated)?_c('span',{on:{"click":function($event){$event.stopPropagation();}}},[((col.type || typeof _vm.value(col)) === 'boolean')?_c('b-form-checkbox',{staticClass:"no-drag hoverable-reveal",attrs:{"size":col.small ? 'sm' : null,"checked":_vm.value(col)},on:{"change":function($event){return _vm.commitEdit(col, $event)}}}):_vm._e(),_c('b-progress',{class:{
            'hoverable-default': true,
            [`bg-deep-${_vm.themeVariant}`]: true,
          },staticStyle:{"height":"5px","margin":"10px 5px 0"},attrs:{"variant":_vm.variant,"value":_vm.value(Object.assign({}, col, { id: col.hoverable })),"max":100}})],1):_c('span',[_vm._v(" "+_vm._s(_vm.value(col) || ((_vm.parentActive || _vm.active) ? '—' : ''))+" ")])]):_vm._e()],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }