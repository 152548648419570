<template>
  <b-form
    inline
    :class="`p-2 d-flex bg-${themeVariant}`"
    @submit.prevent
  >
    <v-icon
      :class="{
        'mr-1': true,
        'disabled cursor-default': disabled,
        'text-muted cursor-pointer': !disabled,
      }"
      :dark="dark"
      @click="!disabled && newItem()"
    >mdi-plus</v-icon>
    <b-form-input
      v-hotkey="Object.assign({}, ...hotkeys.map(
        key => ({
          [key]: () => !disabled && newItem(),
        }),
      ))"
      @keyup.enter="!disabled && newItem()"
      :id="`nav-${name}-new`"
      :disabled="disabled"
      v-model="name"
      :placeholder="disabled
        ? `Select a ${typeName}`
        : `Add ${typeName} ${next} ${hotkeys[0] || ''}`
      "
      size="sm"
      :class="{
        'flex-grow-1 slim rounded border-0': true,
        [`bg-search${dark ? '-dark' : ''} text-${textVariant}`]: true,
      }"
      autocomplete="off"
    ></b-form-input>
  </b-form>
</template>

<script>
import { get, isEqual } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  props: {
    nav: String,
    source: String,
    typeName: String,
    params: { type: Object, default: () => ({}), optional: true },
    hotkeys: { type: Array, default: () => [], optional: true },
    disabled: { type: Boolean, default: false, optional: true },
    minimumCounter: { type: Number, default: 1, optional: true },
    factory: { type: Function, default: (source, meta) => meta },
  },
  data: () => ({
    name: null,
  }),
  computed: {
    ...mapGetters([
      'dark',
      'textVariant',
      'themeVariant',
    ]),
    ...mapState({
      counter({}, { nav }) {
        return nav[this.nav].counters[this.source];
      },
      itemList({ route: { params: { projectId }}}) {
        if (this.items) {
          return this.items;
        }
        const items = this.$store.getters[
          `${this.source.split('.')[0]}/getQuery`
        ](this.source, projectId);

        if (items.loading) {
          return [];
        }
        return items;
      },
      selectByParam({}, { nav }) {
        return nav[this.nav].selected[this.source];
      },
      selectedItem() {
        return this.itemList.find(
          item => item.id === item.params[this.selectByParam]
        );
      },
    }),
    next() {
      const min = parseInt(get(this.selectedItem, this.counter.split('.')));
      let found = 0;
      for (let item of this.itemList) {
        const order = parseInt(get(item, this.counter.split('.')));
        if (order < min) {
          continue;
        }
        if (found + 1 === order) {
          found = order;
          continue;
        } else {
          break;  // order skipped contiguous numbers, stop looking
        }
      }
      return found + 1;
    },
  },
  methods: {
    ...mapActions([
      'query',
    ]),
    newItem(metaBase) {
      const [ area, resource ] = this.source.split('.');
      const params = Object.assign(
        {},
        this.$route.params,
        { [this.selectByParam]: undefined },
        this.params
      );
      const meta = this.factory(this.source, Object.assign(
        {
          '#': this.name || this.$t(`${this.source}-singular`),
          order: this.next,
          params,
          // tags: { 'isNew': true },
        },
        metaBase
      ));
      this.query({
        force: true,
        resource: `${area}.newItems`,
        query: {
          loading: true,
          id: null,
          resource,
          params,
          meta,
          blockList: meta.blockList,  // undefined if not given by factory
        },
      });
      this.name = null;
    },
  },
}
</script>
