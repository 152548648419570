<template>
  <div>
    <div v-for="theme in themes" :key="theme.name">
      <Sample
        v-bind="theme"
        @set-theme="setTheme($event)"
      />
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import Sample from './Sample.vue';

export default {
  computed: {
    ...mapState({
      themes: ({ settings: { themes }}) => {
        return orderBy(
          Object.entries(themes).map(
            ([name, baseTheme]) => Object.assign({}, baseTheme, { name })
          ),
          ['order']
        );
      },
    }),
    ...mapGetters([
      'themeStyle',
    ]),
  },
  methods: {
    ...mapActions([
      'setTheme',
    ]),
  },
  components: {
    Sample,
  },
};
</script>
