var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`text-${_vm.textVariant}`},[_c('div',{staticClass:"text-center"},[_c('b-form-group',[_c('b-form-radio-group',{attrs:{"id":"report-type","name":"type","buttons":"","button-variant":"outline-mango","options":_vm.topics},model:{value:(_vm.topic),callback:function ($$v) {_vm.topic=$$v},expression:"topic"}})],1)],1),_c('b-form-group',{attrs:{"label":"Location:","label-for":"report-url","description":"This is the page you're viewing."}},[_c('b-input',{class:`bg-search${_vm.dark ? '-dark' : ''} text-${_vm.textVariant}`,attrs:{"name":"url","id":"report-url","size":"sm","disabled":""},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1),_c('b-form-group',{attrs:{"label":"Subject:","label-for":"report-subject","description":"This is the page you're viewing."}},[_c('b-input',{class:`bg-search${_vm.dark ? '-dark' : ''} text-${_vm.textVariant}`,attrs:{"name":"title","id":"report-subject","size":"md"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('b-form-group',{attrs:{"label":"Description:","description":"A short description."}},[_c('Editor',{attrs:{"item":_vm.item,"target-area":"@account","format-type":"report","use-store":false}})],1),_c('b-button',{attrs:{"block":"","variant":"outline-mango"},on:{"click":function($event){_vm.showRecent = !_vm.showRecent}}},[_vm._v(_vm._s(_vm.$t('Show recent activity')))]),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRecent),expression:"showRecent"}],staticClass:"mt-2"},[_c('b-col',[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('Queries')))]),_c('b-list-group',_vm._l((_vm.recentInputs),function(log,i){return _c('b-list-group-item',{key:i,class:{
            'd-flex justify-content-start align-items-center': true,
            [`bg-search${_vm.dark ? '-dark' : ''}`]: true,
          }},[_vm._v(" 📍 "),_c('b-badge',{attrs:{"variant":_vm.activityVariant(log)}},[_vm._v(_vm._s(log.service))]),_vm._v(" "+_vm._s(log.resource.split('.', 1)[0])+" ")],1)}),1)],1),_c('b-col',[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('Logs')))]),_c('b-list-group',_vm._l((_vm.recentLogs),function(log,i){return _c('b-list-group-item',{key:i,class:{
            'd-flex justify-content-start align-items-center': true,
            [`bg-search${_vm.dark ? '-dark' : ''}`]: true,
          }},[_vm._v(" "+_vm._s(log.marker)+" "),_c('b-badge',{attrs:{"variant":_vm.activityVariant(log)}},[_vm._v(_vm._s(log.service))]),_vm._v(" "+_vm._s(log.resource.replaceAll(`${log.service}.`, ''))+" ")],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }