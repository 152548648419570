
<template>
  <div :class="{
    [`editor zoom-${editorSize}`]: true,
    justify: editorJustify,
  }">
    <EditorFloatingMenu
      v-slot="{ commands, isActive, menu }"
      :editor="editor(item)"
    >
      <div
        :class="{
          'inline-menu position-absolute': true,
          'invisible': !menu.isActive,
          'visible active': menu.isActive,
        }"
        :style="`top: ${menu.top + offset() - 4}px;`"
      >
        <v-icon
          v-if="sceneControls"
          small
          :class="`mx-1 text-${textVariant}`"
          @click="commands.horizontal_rule()"
        >mdi-format-page-break</v-icon>
        <v-icon
          v-if="sceneControls"
          small
          @click="commands.heading({ level: 2 })"
          :class="{
            [`mx-1 text-${textVariant}`]: true,
            'active': isActive.heading({ level: 2 }),
          }"
        >mdi-format-header-1</v-icon>
        <v-icon
          small
          @click="commands.bullet_list()"
          :class="{
            [`mx-1 text-${textVariant}`]: true,
            'active': isActive.bullet_list(),
          }"
        >mdi-format-list-bulleted-square</v-icon>
        <v-icon
          v-if="sceneControls"
          small
          @click="commands.blockquote()"
          :class="{
            [`mx-1 text-${textVariant}`]: true,
            'active': isActive.blockquote(),
          }"
        >mdi-format-indent-increase</v-icon>
      </div>
    </EditorFloatingMenu>
    <div :class="{
      [formatType]: true,
      'gutters': sceneControls,
    }">
      <EditorContent
        :editor="editor(item)"
        :spellcheck="true"
        @contextmenu="setContext({ scope: 'editor' })"
      />
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import { EditorFloatingMenu, EditorContent } from 'tiptap';

export default {
  props: {
    frame: null,
    targetArea: String,
    useStore: { type: Boolean, default: true },
    item: Object,
    index: { type: Number, optional: true, default: null },
    expandable: { type: Boolean, optional: true, default: true },
    sceneControls: { type: Boolean, optional: true, default: false },
    formatType: String,
  },
  computed: {
    ...mapState('@author', [
      'chapterId',
      'sceneId',
    ]),
    ...mapState([
      'area',
    ]),
    ...mapGetters([
      'themeVariant',
      'textVariant',
      'dark',
      'getEditor',
      'editorSize',
      'editorJustify',
    ]),
  },
  methods: {
    ...mapActions([
      'setData',
    ]),
    editor(item) {
      return this.getEditor(
        item,
        this.targetArea || this.area,
        'text',
        this.useStore
      );
    },
    offset() {
      return get(this.frame, 'scrollTop') || 0
    },
    setContext: (...args) => window.context.set(...args),
  },
  components: {
    EditorFloatingMenu,
    EditorContent,
  },
  created() {
    // this.$watch('frame.scrollTop', (v) => {
    //   this.offset = v;
    // });
  },
  beforeDestroy() {
    // this.getEditor(this.item).destroy();
  },
};
</script>

<style lang="scss">
.inline-menu {
  z-index: 1;
  margin-top: 0.2rem;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;

  &.active {
    opacity: 1;
    top: 0;
  }
}

.gutters .ProseMirror {
  padding: 0 0.75in;
}
.zoom-0 .prose .ProseMirror {
  font-size: 12pt;
  line-height: 3em;
}
.zoom-1 .prose .ProseMirror {
  font-size: 16pt;
  line-height: 2.4em;
}
.zoom-2 .prose .ProseMirror {
  font-size: 24pt;
  line-height: 2em;
}
.prose .ProseMirror {
  font-family: 'Georgia';
  > p {
    text-indent: 0.5in;
    margin-bottom: 0;
  }
  > h2 {
    margin-top: 0em;
    font-size: 2em;
    text-align: center;
  }
  > blockquote {
    margin-left: 2em;
    padding-left: 1em;
    border-left: 4px solid black;
  }
  > ul li {
    margin-left: 0.75in;
    > p {
      margin-bottom: 0;
    }
  }
}
#window[theme="dark"] .prose .ProseMirror blockquote {
  margin-left: 2em;
  padding-left: 1em;
  border-left: 4px solid white;
}
.ProseMirror {
  min-height: 20rem;
  outline-style: none;
}
.justify .ProseMirror > p {
  text-align: justify;
  text-justify: inter-word;
}
</style>
