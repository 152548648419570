import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-button',{class:{
    'source-header py-2 text-left font-weight-bold border-0': true,
    [`px-0`]: !_vm.expanded,
    [`px-3 text-light bg-${_vm.variant}`]: _vm.isActive,
    [`mx-0 text-${_vm.inactiveVariant} text-${_vm.textVariant}`]: !_vm.isActive,
    'bg-deep-dark': !_vm.isActive && _vm.dark,
    'bg-deep-light': !_vm.isActive && !_vm.dark,
  },style:(_vm.expanded && _vm.themeStyle('.rounded')),on:{"click":_vm.select}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',[(_vm.icon)?_c(VIcon,{class:{
          'align-baseline mx-1': true,
          [`text-light`]: _vm.isActive,
          [`text-${_vm.inactiveVariant} bg-transparent`]: !_vm.isActive,
        },staticStyle:{"margin-top":"2px"},attrs:{"dark":!_vm.dark,"title":_vm.text}},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_c('span',{staticClass:"align-top",style:(`text-shadow: '0 1px 0 rgba(255, 255, 255, 0.5)`)},[_vm._v(_vm._s(_vm.text))])],1),(_vm.toggleable)?_c('b-col',{attrs:{"cols":"auto"}},[_c(VIcon,{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive),expression:"isActive"}],class:{
          'flip-x cursor-pointer': true,
          'text-light': _vm.isActive,
        },on:{"click":function($event){$event.stopPropagation();return _vm.toggle.apply(null, arguments)}}},[_vm._v("mdi-minus")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }