import {
  get, map, uniq, each, filter, without, find, pick, pickBy, sortBy, isArray,
  isObject, debounce,
} from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';
// import { vuexfireMutations } from 'vuexfire';
import { Editor } from 'tiptap';
import {
  Bold,
  Italic,
  Underline,
  Heading,
  Blockquote,
  ListItem,
  BulletList,
  HorizontalRule,
  History,
  TrailingNode,
} from 'tiptap-extensions';
import { signOut } from 'firebase/auth';
import { doc, getDoc, setDoc, deleteDoc, collection } from 'firebase/firestore';
import { firestore } from '@/firebase';
// import router from '@/router';
import { services } from '@/services';
import themes from '@/themes';

Vue.use(Vuex);

let providers = {};
try {
  providers = require(`@/services/preset-${process.env.IS_ELECTRON ? 'electron' : 'web'}`).default;
} catch (e) {}
const EDITORS = {};

const waitlist = collection(firestore, 'waitlist');
const launchNotices = collection(firestore, 'launch-notices');


function getSend(name, callback) {
  if (services[name] !== undefined) {
    services[name].then(callback);
  }
}

const store = new Vuex.Store({
  namespaced: true,
  state: {
    // isWindows: !!process.env.APPDATA,
    awaitUserStatus: true,
    user: null,  // firebase user object
    authenticated: false,
    waitlist: null,
    launchNotice: null,
    authError: null,
    ready: false,
    route: {
      fullPath: null,
      path: null,
      params: {
        projectId: null,
        chapterId: null,
      },
      meta: {
        area: null,
        activity: null,
        task: null,
        phase: null,
      },
    },

    area: null,
    activity: null,
    activityOverview: null,
    task: null,
    phase: null,
    debug: false,
    settings: {
      theme: {
        name: 'Plain',
        variant: 'dark',
      },
      themes,
    },
    providerOrder: Object.keys(providers),
    providers,
    extensions: {},
    pluginInstallQueue: [],
    queries: {},
    data: {},
    editors: {},
  },
  getters: {
    userId: ({ user: { email } }) => email,
    // isElectron: () => process.env.IS_ELECTRON,
    // isWeb: ({}, { isElectron }) => !isElectron,
    // isMacOS: () => window.device ? window.device.isMacOS() : false,
    getTheme: ({ settings }) =>
      (name, variant) => get(
        settings.themes, name || settings.theme.name
      ).variants[variant || settings.theme.variant] || {},
    theme: ({ settings }) => {
      const baseTheme = get(settings.themes, settings.theme.name);
      return Object.assign(
        {},
        baseTheme,
        baseTheme.variants[settings.theme.variant],
        { variant: settings.theme.variant },
      );
    },
    dark: ({}, { theme: { dark } }) => dark,
    themeStyle: ({}, { getTheme }) =>
      (styleName, { name=null, variant=null }={}) => {
        return (getTheme(name, variant).styles || {})[styleName] || {};
      },
    themeVariant: ({}, { theme }) => theme.variant,
    textVariant: ({}, { theme }) => theme.textVariant,
    areaVariant: ({}, { dark, currentArea }) =>
      currentArea.variant
        ? `${currentArea.variant}${dark ? '-dark' : ''}`
        : 'neutral',
    activityVariant: ({}, { dark, currentActivity }) =>
      currentActivity.variant
        ? `${currentActivity.variant}${dark ? '-dark' : ''}`
        : null,

    authenticated: state => state.authenticated,
    authProviderId: ({ user }) => {
      if (!user || !user.providerData) {
        return null;
      }
      if (user.providerData.length) {
        return user.providerData[0].providerId.replace(/\.com$/, '')
      } else {
        console.log(user);
        return 'custom';
      }
    },

    getWorkspace: state => area => get(state, `${area}.workspace`),
    workspace: ({ area }, { getWorkspace }) => getWorkspace(area),
    nav: ({}, { workspace }) => get(workspace, 'nav', {}),

    /* Navigation breadcrumbs */
    currentArea: ({ area }, { getArea }) => getArea(area),
    currentActivity: ({ area }, { getAreaActivities }) =>
      find(getAreaActivities(area), 'active') || {},
    currentTask: ({}, { currentActivity, getActivityTasks }) =>
      find(getActivityTasks(currentActivity.activity || {}), 'active', {}),

    /* Service interfaces */
    getData: ({ data, route: { params: { projectId }={} }={} }) =>
      name => get(data, `${projectId}/${name}`),
    getQuery: ({ queries }) => (nickname, ids=undefined) => {
      // console.log('q', nickname, get(queries, nickname));
      const items = get(queries, nickname);
      return ids ? filter(items, item => ids.indexOf(item.id) > -1) : items;
    },
    getTag: () =>
      (item, name, defaultValue) => name
        ? get(item, name.split('.')) || defaultValue
        : defaultValue,

    /* Providers */
    providerOrder: state => state.providerOrder,
    providers: state => state.providers,
    getProviderServiceConfig: ({ providers }) =>
      (id, serviceName) => providers[id][serviceName],
    workspaceProvider: ({}, { workspace }) => get(workspace, 'services.provider'),
    // providerServices: ({ providers }, { servicesProvider: id }) => providers[id] || {},

    /* Areas */
    areaReady: state => area => get(state.extensions, area) !== undefined,
    orderedAreas: ({ extensions }) =>
      sortBy(Object.values(extensions), ['order', 'area']),
    areas: ({ extensions, route }) =>
      Object.assign({}, ...Object.values(extensions).map((config) => ({
        [config.area]: Object.assign(
          { active: route.path.startsWith(`/${config.area.substring(1)}`) },
          config
        ),
      }))),
    getArea: ({ extensions, route }) => id => Object.assign(
      { active: id ? route.path.startsWith(`/${id.substring(1)}`) : false },
      extensions[id]
    ),
    getAreaActivities: ({ extensions, route }, { workspaceItem }) =>
      areaId => map(
        get(extensions, `${areaId}.activities`, []),
        (activity) => Object.assign({}, activity, (() => {
          const { passable=[] } = activity.meta || {};
          const { params: { projectId=null }={} } = workspaceItem || {};
          const active = route.path.startsWith(
            `/${areaId.substring(1)}/${activity.path}`
          );
          return {
            active,
            lowParams: (!active
              && passable !== true  // isn't explicitly allowed
              && (passable || []).indexOf('projectId') >= -1  // is passable...
              && !projectId  // ...but no selected item
              && !route.params.projectId  // ...but no routed item
            ),
          };
        })())
      ),

    /* Activities */
    getActivityTasks: ({ task }) => ({ children }) => map(
      filter(children || [], 'meta.task'),
      t => Object.assign({}, t, { active: t.meta.task === task })
    ),

    /* Embedded sources (virtual queries) */
    getEmbeddedRoot: ({ area, route }, getters) => (source, id, subSource) => {
      const item = (
        getters[`${area}/getQuery`](source, route.params.projectId) || []
      ).find(item => item.id === id);
      return getters.getTag(item.meta, subSource, {});
    },
    getEmbeddedList: ({}, { getEmbeddedRoot }) =>
      (source, id, subSource, params) => {
        const root = getEmbeddedRoot(source, id, subSource);
        if (!root) {
          return null;
        }
        return Object.entries(root).map(([id, item]) => Object.assign(
          {},
          item,
          { id, params }
        ));
      },

    /* Text editor */
    activeEditor: ({}, { workspaceItem, getEditor }) =>
      getEditor(workspaceItem),
    workspaceItem: ({ area }, { workspace }) => {
      const { resource, id } = get(workspace, 'location', {});
      if (!id) {
        return undefined;
      }
      return find(
        store.getters[`${area}/getQuery`](`${area}.${resource}`) || [
          get(workspace, 'location', {})
        ],
        { id }
      );
    },
    getEditor: ({ area }) => (item, customArea, attr='text', useStore=true) => {
      if (item === undefined) {
        return undefined;
      }
      const {
        id,
        type,
        params: { projectId, chapterId }={},
        [attr]: text='',
      } = item;
      const nickname = `editor-${type}-${chapterId}-${item.id}`;

      if (EDITORS[nickname] !== undefined) {
        return EDITORS[nickname];
      }

      const update = debounce((getHTML) => {
        if (!useStore) {
          return;
        }
        console.log('updating', id);
        // store.dispatch('modifyQueryItem', { item, info: { text: getHTML() } });
        store.dispatch(`${customArea || area}/query`, {
          force: true,
          projectId,
          nickname,
          resource: `modify${
            type[0].toUpperCase() + type.substring(1).toLowerCase()
          }`,
          query: Object.assign({}, item, { [attr]: getHTML() }),
        });
      }, 800, { trailing: true });

      EDITORS[nickname] = new Editor({
        content: text,
        extensions: [
          new HorizontalRule(),
          new Bold(),
          new Italic(),
          new Underline(),
          new Blockquote(),
          new ListItem(),
          new BulletList(),
          new Heading(),
          new History(),
          new TrailingNode({ node: 'paragraph', notAfter: ['paragraph'], }),
        ],
        onUpdate({ getHTML }) {
          update(getHTML);
        },
        onFocus() {
          store.dispatch(`${area}/setLocation`, item);
        },
        onTransaction: ({ state }) => {
          // console.log(state.selection.anchor);
        },
      });

      return EDITORS[nickname];
    },
    editorSettings: ({}, { workspace }) =>
      get(workspace, 'editor'),
    editorFullscreen: ({}, { workspace }) =>
      get(workspace, 'editor.fullscreen'),
    editorThemes: ({}, { workspace }) =>
      get(workspace, 'editor.themes'),
    editorAppearance: ({}, { workspace }) =>
      get(workspace, 'editor.appearance'),
    editorTheme: ({}, { editorThemes, editorAppearance }) =>
      find(editorThemes, { name: editorAppearance }),
    editorSize: ({}, { workspace }) =>
      parseInt(get(workspace, 'editor.size', 1)),
    editorJustify: ({}, { workspace }) =>
      get(workspace, 'editor.justify', false),
    editorExtendedFormatting: ({}, { workspace }) =>
      get(workspace, 'editor.extendedFormatting'),
    editorShyTools: ({}, { workspace }) =>
      get(workspace, 'editor.shyTools'),
    useFullscreenBackground: ({}, { workspace }) =>
      get(workspace, 'editor.useFullscreenBackground'),
  },
  actions: {
    queuePluginInstall({ commit }, url) {
      commit('QUEUE_PLUGIN_INSTALL', url);
    },
    removeQueuedPlugin({ commit }, url) {
      commit('REMOVE_QUEUED_PLUGIN', url);
    },
    updateWaitlist({ commit, state: { user } }, status=undefined) {
      if (!user) {
        return;
      }
      if (status === undefined) {
        getDoc(doc(waitlist, user.email || 'anonymous'))
          .then((ref) => {
            const exists = ref.exists();
            console.log('waitlist', exists);
            commit('SET_WAITLIST', exists);
          })
          .catch(console.error)
          ;
      } else {
        const d = doc(waitlist, user.email || 'anonymous');
        if (status) {
          setDoc(d, {}).then(() => commit('SET_WAITLIST', status))
            .catch(
              e => console.warn('waitlist error read', user.email, `${e}`)
            );
        } else {
          deleteDoc(d).then(() => commit('SET_WAITLIST', status))
            .catch(
              e => console.warn('waitlist error remove', user.email, `${e}`)
            );
        }
      }
    },
    updateLaunchNotice({ commit, state: { user } }, status=undefined) {
      if (!user) {
        return;
      }
      if (status === undefined) {
        getDoc(doc(launchNotices, user.email || 'anonymous'))
          .then((ref) => {
            const exists = ref.exists();
            console.log('launch-notice', exists);
            commit('SET_LAUNCH_NOTICE', exists);
          })
          .catch(console.error)
          ;
      } else {
        const d = doc(launchNotices,
          user.email || 'anonymous'
        );
        if (status) {
          setDoc(d, {}).then(() => commit('SET_LAUNCH_NOTICE', status))
            .catch(
              e => console.warn('launch-notice error read', user.email, `${e}`)
            );
        } else {
          deleteDoc(d).then(() => commit('SET_LAUNCH_NOTICE', status))
            .catch(
              e => console.warn('launch-notice error del', user.email, `${e}`)
            );
        }
      }
    },
    awaitUser({ commit }) {
      commit('AWAIT_USER');
    },
    setUser({ commit }, user) {
      if (user === null) {
        commit('SET_USER', null);
      } else {
        commit('SET_USER', user);
      }
    },
    setAuthIssue({ commit }, error) {
      commit('SET_AUTH_ISSUE', error);
    },
    logOut({ commit }) {
      commit('REVOKE_READY');
      commit('SET_AUTH_ISSUE', {});
      signOut();
    },
    release({ commit }, membership) {
      commit('SET_READY');
    },
    setProviderEnv({ commit }, { providerId, env }) {
      commit('SET_PROVIDER_ENV', { providerId, env });
    },
    async clearState({}, area) {
      getSend(area, send => send(`${area}.cache`, { state: {} }));
    },
    async saveState({ state }, { area=undefined }={}) {
      const areas = area ? [area] : Object.keys(services);
      // send('ink.cache', { uid, state: pickBy(state, (v, k) => !k.startsWith('@')) });
      Promise.all(areas.map(area => getSend(area, async (send) => {
        send(`${area}.cache`, { state: state[area] });
      })));
    },
    loadState({ commit }, state) {
      commit('LOAD_STATE', state);
      // if (state.route) {
      //   router.push(state.route);
      // }
    },
    setRoute({ commit }, { component, ...route }) {
      commit('SET_ROUTE', route);
    },

    /**
     * Sends an api message to the provider behind `providerId`, to the endpoint
     * called `resource`.  `nickname` is a cache key name that will be returned
     * instead if the query has already completed.
     */
    async query(
      { dispatch, state: { providers }, getters: { userId }},
      { nickname, providerId, resource, query, force=false }
    ) {
      const [ area ] = resource.split('.', 1);
      const result = store.getters[`${area}/getQuery`](nickname);

      if (!force && result !== undefined) {
        return;
      }
      dispatch('setQuery', { nickname, query, loading: true });

      const message = Object.assign(
        query,  // user params
        (providers[providerId] || {})[area] || {}, // provider-enforced params
        { nickname },  // context
      );
      getSend(area, (send) => send(resource, message));
    },
    setDebug({ commit }, value) {
      commit('SET_DEBUG', value);
    },
    setTheme({ commit }, { name, variant }) {
      commit('SET_THEME', { name, variant });
    },
    setArea({ commit, state }, area) {
      if (area != state.area) {
        commit('SET_AREA', area);
      }
    },
    setActivity({ commit, state }, activity) {
      if (activity != state.activity) {
        commit('SET_ACTIVITY', activity);
      }
    },
    setActivityOverview({ commit, state: { activityOverview } }, overviewName) {
      if (overviewName != activityOverview) {
        commit('SET_ACTIVITY_OVERVIEW', overviewName);
      }
    },
    setTask({ commit, state }, task) {
      if (task !== state.task) {
        commit('SET_TASK', task);
      }
    },
    setPhase({ commit, state }, phase) {
      if (phase !== state.phase) {
        commit('SET_PHASE', phase);
      }
    },
    request({ state }, { name, event, message, force }) {
      const nickname = name || event;
      if (!force && state.data[nickname] !== undefined) {
        return;
      }
      Events.$emit(`dev:${event}`, message);
    },
    setData({ commit }, { name, data, loading=false }) {
      commit('STORE_DATA', { name, data, loading });
    },
    async setQuery({ commit, getters: { workspace } }, message) {
      const { nickname, data, partial=false, loading=false } = message;
      // console.log('set', message);
      if (partial) {
        commit('APPEND_TO_QUERY', { uid: nickname, items: data });
      } else {
        commit('STORE_QUERY', { name: nickname, data, loading });
      }
      if (workspace) {
        map(workspace.nav, (nav, name) => {
          commit('FOLD_NAVIGATOR_SOURCES', name);
        });
      }
    },
    modifyQueryItem: (
      { commit },
      { item: { area, resource, id, projectId }, info }
    ) => {
      commit('MODIFY_QUERY_ITEM', {
        uid: store.getters[`${area}/scopedName`](
          `${area}.${resource}`, projectId
        ),
        id,
        info,
      });
    },

    connectNavSource({ commit }, { name, activate }) {
      commit('UPDATE_NAVIGATOR', { sources: [name] });
      if (activate) {
        commit('UPDATE_NAVIGATOR', { source: name });
      }
    },
    configureNav(
      { commit, state: { area } },
      { name, expanded, sources, source, selected, folds, columns }
    ) {
      // console.log('config nav', name, selected)
      if (area) {
        commit('UPDATE_NAVIGATOR', {
          name, expanded, sources, source, selected, folds, columns,
        });
        commit('FOLD_NAVIGATOR_SOURCES', name);
      }
    },

    setWidget({ commit }, { name, ...info }) {
      commit('UPDATE_WIDGET', { name, info });
    },

    // addProvider({ commit }, id, { location, data }) {
    //   commit('SET_PROVIDER_LOCATION', { id, location });
    //   commit('MERGE_PROVIDER_DATA', { id, data });
    // },
    enableProvider({ commit, state }, id) {
      if (state.providerOrder.indexOf(id) === -1) {
        console.log(id, 'provider enabled');
        commit('SET_PROVIDERS_ORDER', {
          order: state.providerOrder.concat([id]),
        });
      }
    },
    // disableProvider({ commit, state }, id) {
    //   if (state.providerOrder.indexOf(id) !== -1) {
    //     commit('SET_PROVIDERS_ORDER', { order: without(state.providerOrder, id) });
    //   }
    // },
    serviceStarted({ commit }, { providerId, name }) {
      commit('SET_SERVICE_STATUS', { providerId, name, status: true });
    },
    serviceLost({ commit }, { providerId, name }) {
      commit('SET_SERVICE_STATUS', { providerId, name, status: false });
    },
    enableActivities({ commit }, info) {
      commit('SET_AREA_ACTIVITIES', info);
    },

    pushTag({ state }, {
      source, tag, item, value, annotator=undefined, freeze=[],
    }) {
      const [ area ] = source.split('.', 1);
      const projectId = state.route.params.projectId;
      store.dispatch(`${area}/pushTag`, {
        projectId,
        type: item.type,
        id: item.id,
        tag,
        value,
        annotator,
        freeze: freeze.length ? freeze : ['id'],
      });
    },
    modifyTag(
      {},
      { tag, value, item: { area, type, id, params: { projectId } }}
    ) {
      store.dispatch(`${area}/pushTag`, { projectId, type, id, tag, value });
    },
  },
  mutations: {
    // ...vuexfireMutations,
    QUEUE_PLUGIN_INSTALL(state, url) {
      state.pluginInstallQueue.push(url);
    },
    REMOVE_QUEUED_PLUGIN(state, url) {
      state.pluginInstallQueue = without(state.pluginInstallQueue, url);
    },
    SET_WAITLIST(state, status) {
      state.waitlist = status;
    },
    SET_LAUNCH_NOTICE(state, status) {
      state.launchNotice = status;
    },
    AWAIT_USER(state) {
      state.awaitUserStatus = true;
    },
    SET_USER(state, user=null) {
      const u = !user ? null : pick(user, [
        'displayName',
        'email',
        'photoURL',
        'providerData',
      ]);
      Vue.set(state, 'user', u);
      state.awaitUserStatus = false;
    },
    SET_AUTH_ISSUE(state, error) {
      const { code, message } = error;
      if (code) {
        Vue.set(state, 'authError', { code, message });
        state.awaitUserStatus = false;
      } else if (error) {
        throw error;
      } else {
        state.authError = null;
      }
    },
    REVOKE_READY(state) {
      state.ready = false;
    },
    SET_READY(state) {
      state.ready = true;
    },
    SET_PROVIDER_ENV(state, { providerId, env }) {
      const info = state.providers[providerId];
      info.env = { ...info.env, ...env };
    },
    LOAD_STATE(state, newState) {
      console.log('restore', newState);
      each(newState, (v, k) => {
        if (isArray(v)) {
          Vue.set(state, k, [ ...newState[k] ]);
        } else if (isObject(v)) {
          Vue.set(state, k, { ...newState[k] });
        } else {
          Vue.set(state, k, v);
        }
      });
    },
    SET_ROUTE(state, route) {
      const passable = get(route, 'meta.passable', null);
      const obj = {
        ...state.route,
        ...pick(route, Object.keys(state.route)),
        params: pick(route.params, passable === true
          ? Object.keys(state.route.params)
          : passable
        ),
      };
      // console.log('route', route, obj);
      state.route = obj;
    },
    SET_DEBUG(state, value) {
      state.debug = value;
    },
    SET_THEME(state, { name, variant }) {
      Vue.set(state.settings, 'theme', Object.assign(
        state.settings.theme,
        pickBy({ name, variant }))
      );
    },
    SET_AREA(state, area) {
      console.log('area', area);
      state.area = area;
    },
    SET_ACTIVITY(state, name) {
      console.log('activity', name);
      state.activity = name;
    },
    SET_ACTIVITY_OVERVIEW(state, name) {
      state.activityOverview = name;
    },
    SET_TASK(state, name) {
      console.log('task', name);
      state.task = name;
    },
    SET_PHASE(state, name) {
      state.phase = name;
    },
    MODIFY_QUERY_ITEM(state, { uid, id, info }) {
      const existing = find(state.queries[uid], { id });
      const i = state.queries[uid].indexOf(existing);
      if (i > -1) {
        Vue.set(state.queries[uid], i, { ...(existing || {}), ...info, id });
      } else {
        state.queries[uid].push({ ...info, id });
      }
    },
    APPEND_TO_QUERY(state, { uid, items }) {
      items.forEach((item) => {
        const existing = find(state.queries[uid], { id: item.id });
        const i = state.queries[uid].indexOf(existing);
        if (i > -1) {
          Vue.set(state.queries[uid], i, item);
        } else {
          state.queries[uid].push(item);
        }
      });
      state.queries = { ...state.queries  };
    },
    STORE_DATA(state, { name, data, loading }) {
      const { route: { params: { projectId }={} }={} } = state;
      state.data = {
        ...state.data,
        [`${projectId}/${name}`]: (data || { loading }),
      };
    },
    STORE_QUERY(state, { name, data, loading }) {
      // console.log('store', name, data);
      state.queries = { ...state.queries, [name]: (data || { loading }), };
    },
    UPDATE_NAVIGATOR(state, {
      name, expanded, sources, source, selected, folds, columns,
    }) {
      const nav = get(state, `${state.area}.workspace.nav.${name}`);
      if (nav === undefined) {
        console.error(`Nav backend '${state.area}.workspace.nav' unavailable!`);
        return;
      } else if (nav === false) {
        console.log(
          `Nav backend '${state.area}.workspace.nav' declined update.`
        );
        return;
      }
      if (expanded !== undefined) {
        nav.expanded = expanded;
      }
      if (sources !== undefined) {
        nav.sources = uniq(nav.sources.concat(sources));
      }
      if (source !== undefined) {
        nav.source = source;
      }
      if (selected !== undefined) {
        nav.selected = { ...nav.selected, ...selected };
      }
      if (folds !== undefined) {
        map(folds, (subSources, idParam) => {
          Vue.set(nav.folds, idParam, {
            ...(nav.folds[idParam] || {}),
            ...subSources
          });
        });
      }
      if (columns !== undefined) {
        Vue.set(nav.columns, source, [...columns]);
      }
    },
    FOLD_NAVIGATOR_SOURCES(state, name) {
      const nav = get(state, `${state.area}.workspace.nav.${name}`);
      if (nav.joins === undefined) {
        Vue.set(nav, 'joins', {});
      }
      map(nav.folds || {}, (sources, param) => {
        const uid = store.getters[`${state.area}/scopedName`](nav.source);
        const items = state.queries[uid];
        map(sources, (enabled, source) => {
          const [ area ] = source.split('.', 1);
          const sourceUid = store.getters[`${area}/scopedName`](source);
          if (!items || items.loading) {
            return;
          }
          map(items, (item) => {
            if (nav.joins[item.id] === undefined) {
              Vue.set(nav.joins, item.id, {});
            }
            const subItems = state.queries[sourceUid] || [];
            if (!subItems || subItems.loading) {
              return;
            }
            const hits = !enabled ? [] : filter(
              subItems,
              subItem => (subItem.params || {})[param] == item.id
            );
            Vue.set(nav.joins[item.id], source, map(hits, 'id'));
            // console.log(item.id, source, map(hits, 'id'));
          });
        })
      });
    },
    SET_NAV_PRESENTATION_FILTER(state, { source, filter }) {
      filter = pickBy(Object.assign({}, filter, { scrapId: undefined }))
      const nav = get(state, `${state.area}.workspace.nav`);
      Object.assign(nav, {presentationFilters: {[source]: filter}})
    },
    UPDATE_WIDGET(state, { name, info }) {
      const workspace = get(state, [state.area, 'workspace']);
      if (workspace.widgets[name] === undefined) {
        Vue.set(workspace.widgets, name, {})
      }
      workspace.widgets[name] = { ...workspace.widgets[name], ...info };
    },
    SET_AREA_ACTIVITIES(state, info) {
      state.extensions = { ...state.extensions, [info.area]: info };
    },
    SET_PROVIDERS_ORDER(state, { order }) {
      state.providerOrder = order;
    },
    SET_PROVIDER_LOCATION(state, { id, location }) {
      state.providers[id].location = location;
    },
    SET_SERVICE_STATUS({ providers }, { providerId, name, status }) {
      providers[providerId][name].status = status;
    },
    MERGE_PROVIDER_DATA(state, { id, data }) {
      state.providers[id].data = data;
    },
  },
});

export default store;
