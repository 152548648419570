export const light = {
  '#logo': {
    'filter': 'invert(100%) sepia(0%) saturate(1012%) hue-rotate(43deg) brightness(115%) contrast(75%)',
  },
};
export const dark = {
  '#logo': {
    'filter': 'invert(19%) sepia(0%) saturate(0%) hue-rotate(22deg) brightness(97%) contrast(89%)',
  },
};
