import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/i18n/en_US'

// function getLocaleCode() {
//   if (process.versions.electron) {
//     const { app } = require('electron').remote;
//     return app.getLocale();
//   }
//   // eslint-disable-next-line
//   return undefined;
// }

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en_US', // getLocaleCode()
  fallbackLocale: 'en_US',
  messages,
});
export default i18n;
