<template>
  <div>
    <div id="auth"
      :class="{
        [`w-100 mx-auto my-3 text-center text-${textVariant}`]: true,
        'auth-selected': !!selectedProvider,
      }"
    >
      <div v-show="awaitUserStatus" class="spinner-border">
        <span class="sr-only">Logging in...</span>
      </div>
      <div v-show="!awaitUserStatus">
        <div v-if="isWeb">
          <h6>Sign in with</h6>
          <b-nav align="center">
            <b-nav-item
              v-for="provider in authProviders"
              :key="provider"
              @click.prevent="logIn(provider)"
            >
              <ProviderIcon
                :provider="provider"
                :selected="provider === selectedProvider"
              />
            </b-nav-item>
          </b-nav>
        </div>
        <b-button v-else @click="startRemoteLogin">
          Get Started
        </b-button>
        <!-- <div v-else>
          <h6></h6>
          <b-row align="center">
            <b-col cols="2">
              <v-icon x-large :class="`text-${textVariant}`"
              >mdi-{{ providerIcons.email }}</v-icon>
            </b-col>
            <b-col>
              <b-form @submit.prevent="logIn()">
                <b-input v-model="uuid" placeholder="Email" />
              </b-form>
            </b-col>
            <b-col cols="2">
              <v-icon x-large :class="`text-${textVariant}`"
                @click="logIn()"
              >mdi-arrow-right-thin-circle-outline</v-icon>
            </b-col>
          </b-row>
        </div> -->
      </div>
    </div>

    <div v-if="!awaitUserStatus && authError"
      :class="`mx-auto my-3 overflow-hidden text-left`"
    >
      <b-row no-gutters class="my-3">
        <b-col cols="2" class="text-center">
          <v-icon class="text-muted" style="font-size: 50px">mdi-rocket-launch</v-icon>
        </b-col>
        <b-col class="px-3 pt-2">
          <span v-if="[
            'auth/account-exists-with-different-credential',
            'auth/invalid-user-token',
          ].indexOf(authError.code) > -1">Welcome back.</span>
          <span v-else-if="[
            'ink/invitation',
          ].indexOf(authError.code) > -1">Ink is in private beta.</span>
          <span v-else>Login was interrupted.</span>
        </b-col>
      </b-row>

      <b-row no-gutters v-if="authError.code === 'ink/invitation'"
        class="my-3"
      >
        <b-col cols="2" class="text-center">
          <v-icon class="text-muted" style="font-size: 50px">mdi-human-queue</v-icon>
        </b-col>
        <b-col class="px-3">
          <div class="mx-auto">
            <b-form-checkbox switch v-model="modelWaitlist">
              Join the waitlist
            </b-form-checkbox>
            <b-form-checkbox switch v-model="modelLaunchNotice">
              Get a launch email
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters v-if="authError && authError.code.startsWith('auth/')"
        class="my-3"
      >
        <b-col cols="2" class="text-center">
          <v-icon class="text-muted" style="font-size: 50px">mdi-information</v-icon>
        </b-col>
        <b-col class="px-3">
          <div v-if="authError.code === 'auth/account-exists-with-different-credential'">
            We recognize your email address, but we know you by one of the other providers.
          </div>
          <div v-else-if="authError.code === 'auth/cancelled-popup-request'">
            The {{ $t(selectedProvider) }} login was cancelled.
          </div>
          <div v-else-if="authError.code === 'auth/popup-blocked'">
            The login popup seems to have been blocked!
          </div>
          <div v-else-if="authError.code === 'auth/popup-closed-by-user'">
            You closed the {{ $t(selectedProvider) }} login popup.
          </div>
          <div v-else-if="authError.code === 'auth/invalid-user-token'">
            Refresh your login to continue.
          </div>
          <div v-else-if="authError.code === 'auth/user-disabled'">
            Your Ink account at this email address has been disabled.
            If you believe this was done in error,
            <a href="mailto:humans@mythulu.com">email a human so we can help.</a>
          </div>

          <div v-else>
            Unhandled error code <code>{{ authError.code }}</code>
          </div>
          <div v-if="authError">
            <b-button
              block
              variant="text"
              size="small"
              :class="`text-${textVariant}`"
              @click="showFullAuthError = !showFullAuthError"
            >Show full error</b-button>
            <code class="mt-2" v-show="showFullAuthError">
              <div><strong>{{ authError.code }}</strong></div>
              <div>{{ authError.message }}</div>
            </code>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-row no-gutters v-if="ready" class="my-3">
      <b-col cols="2" class="text-center">
        <v-icon class="text-muted" style="font-size: 50px">mdi-certificate</v-icon>
      </b-col>
      <b-col class="px-3 pt-2">
        You're in the private beta.
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { signInWithPopup } from 'firebase/auth';
import { auth, providers, icons as providerIcons } from '@/firebase';
import { setToken } from '@/token';
import ProviderIcon from './ProviderIcon.vue';

export default {
  data: () => ({
    providerIcons,
    authProviders: Object.keys(providers),
    selectedProvider: null,
    showFullAuthError: false,
  }),
  computed: {
    ...mapState([
      'awaitUserStatus',
      'ready',
      'waitlist',
      'launchNotice',
      'authError',
    ]),
    ...mapGetters([
      'textVariant',
    ]),
    isWeb: () => window.device.isWeb(),

    modelWaitlist: {
      get() {
        return this.waitlist;
      },
      set(status) {
        this.updateWaitlist(status);
      },
    },
    modelLaunchNotice: {
      get() {
        return this.launchNotice;
      },
      set(status) {
        this.updateLaunchNotice(status);
      },
    },
  },
  methods: {
    ...mapActions([
      'awaitUser',
      'setAuthIssue',
      'updateWaitlist',
      'updateLaunchNotice',
    ]),
    startRemoteLogin() {
      window.remoteNavigation.startRemoteAuth();
    },
    async logIn(provider) {
      this.selectedProvider = provider;
      this.awaitUser();

      await signInWithPopup(auth, providers[provider])
        .then(async (result) => {
          const { credential: { accessToken }} = result;
          setToken(accessToken);
        })
        .catch((e) => {
          this.setAuthIssue(e);
        })
        ;
    },
  },
  components: {
    ProviderIcon,
  },
};
</script>

<style lang="scss">
#remote-auth {
  border: none;
  width: 100%;
  height: 400px;
  overflow: auto;
}
#auth {
  &:hover .auth-icon {
    opacity: 0.4;
    &:hover {
      opacity: 1.0;
    }
  }
  .auth-icon {
    opacity: 1.0;
    transition: opacity .15s ease-in-out;
  }
}
</style>
