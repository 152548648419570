/**
 * US English
 * @name i18n/en_US
 * @exports default
 */
module.exports = {
  /**
   * Translation strings
   */
  en_US: {
    _: 'English',

    // #region application
    /** Application title. */
    'ink': 'Ink',

    /** Add a plugin to Ink. */
    'install-plugins': 'Add Plugins',

    /** Currently installing plugins to Ink. */
    'installing-plugins': 'Installing...',
    // #endregion application


    // #region modals
    /** Title for settings sidebar. */
    'settings': 'Settings',

    'issue-tracker': 'Issues',
    'theme-picker': 'Theme',

    /** Account settings title. */
    'account': 'Account',
    // #endregion modals

    // #region greetings
    /** Greeting between midnight-6am. */
    'greeting-midnight': 'Good evening.',

    /** Greeting between 6am-noon. */
    'greeting-am': 'Good morning.',

    /** Greeting between noon-6pm. */
    'greeting-afternoon': 'Good afternoon.',

    /** Greeting between 6pm-midnight. */
    'greeting-pm': 'Good evening.',
    // #endregion greetings


    // #region common
    /** Term for counted words. */
    'words': 'word | words',
    /** Filler text using all letters of alphabet. */
    'sample-text': 'How quickly daft jumping zebras vex.',
    // #endregion common


    // #region nav
    /** Help message for column settings. */
    'nav-column-picker-help': 'Adjust direction and column visibility.',
    /** Help message for data hierarchy settings. */
    'nav-column-hierarchy-help': 'Adjust info shown below each item.',
    /** Tooltip for search filter disabled/enabled. */
    'nav-filter-enabled': 'Unfiltered results | Filtered results',
    /** Tooltip for sort customizer. */
    'nav-sort-options': 'Sort options',
    // #endregion nav


    // #region entities
    /** Name of `Project`-type entities. */
    'projects': 'project | projects',

    /** Plural name of `Chapter`-type entities. */
    'chapters': 'chapter | chapters',

    /** Name of `Scene`-type entities. */
    'scene': 'Scene',

    /** Plural name of Scene-type entities. */
    'scenes': 'Scenes',

    /** Name of `Scrap`-type entities. */
    'scrap': 'Scrap',

    /** Plural name of `Scrap`-type entities. */
    'scraps': 'Scraps',

    /** Name of `Task`-type entities. */
    'task': 'Task',

    /** Plural name of `Task`-type entities. */
    'tasks': 'Tasks',

    /** Name of `Goal`-type entities. */
    'goal': 'Goal',

    /** Plural name of `Goal`-type entities. */
    'goals': 'Goals',
    // #endregion entities
  },
}
