import { rounded } from './rounded' ;

const navItemDiagonal = '25px';

export const light = {
  ...rounded,
  '#nav-areas': {
    'background-color': 'var(--titlebar-light)',
    'clip-path': `polygon(${navItemDiagonal} 0, 100% 0, 100% 100%, 0 100%)`,
    '-webkit-clip-path': `polygon(${navItemDiagonal} 0, 100% 0, 100% 100%, 0 100%)`,
  },
  '#content-frame': {
    'margin': '-58px 0 0 0',
    'padding-top': '58px',
  },
  '#sidebar-left': {
    'margin-left': '10px',
  },
  '#sidebar-right': {
    'margin-right': '10px',
  },
  '.nav-area': {
    'padding': '0 20px',
    'margin-left': `-${navItemDiagonal}`,
    'shape-inside': `polygon(${navItemDiagonal} 0, 100% 0, calc(100% - ${navItemDiagonal}) 100%, 0 100%)`,
    '-webkit-clip-path': `polygon(${navItemDiagonal} 0, 100% 0, calc(100% - ${navItemDiagonal}) 100%, 0 100%)`,
  },
  '.nav-task': Object.assign({}, rounded['.rounded'], {
    'margin': '4px',
  }),
  '.nav-task-active': Object.assign({}, rounded['.rounded'], {
    'margin': '4px',
  }),
};

export const dark = {
  ...rounded,
  '#nav-areas': {
    'background-color': 'var(--titlebar-dark)',
    'clip-path': `polygon(${navItemDiagonal} 0, 100% 0, 100% 100%, 0 100%)`,
    '-webkit-clip-path': `polygon(${navItemDiagonal} 0, 100% 0, 100% 100%, 0 100%)`,
  },
  '#content-frame': {
    'margin': '-58px 0 0 0',
    'padding-top': '58px',
  },
  '#sidebar-left': {
    'margin-left': '10px',
  },
  '#sidebar-right': {
    'margin-right': '10px',
  },
  '.nav-area': {
    'padding': '0 20px',
    'margin-left': `-${navItemDiagonal}`,
    'shape-inside': `polygon(${navItemDiagonal} 0, 100% 0, calc(100% - ${navItemDiagonal}) 100%, 0 100%)`,
    '-webkit-clip-path': `polygon(${navItemDiagonal} 0, 100% 0, calc(100% - ${navItemDiagonal}) 100%, 0 100%)`,
  },
  '.nav-task': Object.assign({}, rounded['.rounded'], {
    'margin': '4px',
  }),
  '.nav-task-active': Object.assign({}, rounded['.rounded'], {
    'margin': '4px',
  }),
};
