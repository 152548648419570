var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'flex-grow-1': !_vm.parent,
  },style:(!_vm.parent
    ? { 'overflow-y': 'auto' }
    : {}),attrs:{"source":_vm.source}},[_c('Draggable',{staticClass:"border-0 rounded-0",attrs:{"tag":"b-list-group","ghost-class":"placeholder-from","group":`${_vm.nav}-${_vm.source}`,"filter":".no-drag","prevent-on-filter":false,"flush":"","animation":"","disabled":false},model:{value:(_vm.items),callback:function ($$v) {_vm.items=$$v},expression:"items"}},[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.items),function(item,i){return _c('b-list-group-item',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
          el: item.multiDisplay
            ? `#${item.safeId || `${item.type}-${item.id || i}`}`
            : undefined,
          container: _vm.scrollFrame,
        }),expression:"{\n          el: item.multiDisplay\n            ? `#${item.safeId || `${item.type}-${item.id || i}`}`\n            : undefined,\n          container: scrollFrame,\n        }"}],key:`${_vm.parent}-${_vm.parentId}-${_vm.source}-${item.id}`,class:{
          'rounded-0 border-0 px-0': true,
          'py-1': !_vm.parent,
          'py-0': _vm.parent,
          'delegated-group': _vm.navSpec.delegate[_vm.source],
          [`cursor-pointer bg-transparent text-${_vm.textVariant}`]: !_vm.selected.includes(item.id),
          [`cursor-default bg-${_vm.variant} text-light`]: _vm.selected.includes(item.id),
        },attrs:{"to":undefined /* hack breaks hover/focus, but stops inner input click bug */},on:{"click":function($event){$event.stopPropagation();return _vm.select(item)},"contextmenu":function($event){$event.stopPropagation();return _vm.setContext({ scope: 'navigator', item })}}},[_c('Item',{attrs:{"id":item.id,"data":item,"source":_vm.source,"tag-id":_vm.list ? _vm.parentId : null,"tag-type":_vm.list ? _vm.parentType : null,"tag-path":_vm.list ? _vm.source : null,"columns":_vm.processColumns(_vm.parent, _vm.source),"variant":_vm.variant,"parent-active":_vm.parentActive,"active":_vm.selected.includes(item.id),"hits":item.hits,"delegated":_vm.navSpec.delegate[_vm.source],"delegated-by":_vm.delegatedBy,"small":!_vm.list && Boolean(_vm.parent),"icon":_vm.parent && _vm.$t(`${_vm.source}-icon`),"icon-variant":_vm.dark && _vm.getItemVariant(item) || 'light'}}),_vm._l(((_vm.joins[item.id] || {})),function(ids,subSource){return _c('List',{key:`${_vm.parent}-${_vm.parentId}-${item.id}-${subSource}`,attrs:{"nav":_vm.nav,"source":subSource,"template":_vm.getEmbeddedRoot(_vm.source, item.id, subSource).template,"list":_vm.findEmbeddedList(item, subSource),"delegated-by":_vm.getEmbeddedListName(item, _vm.source),"columns":_vm.processColumns(_vm.source, subSource),"filter":_vm.filter,"joins":_vm.joins,"parent":_vm.source,"parent-type":item.type,"parent-id":item.id,"parent-active":_vm.selected.includes(item.id),"variant":_vm.variant,"scroll-frame":_vm.scrollFrame}})})],2)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }