import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h6',{staticClass:"display-5 text-center"},[_vm._v("Columns")]),_c('span',{staticClass:"help-message"},[_vm._v(_vm._s(_vm.$t('nav-column-picker-help')))]),_c('b-list-group',_vm._l((_vm.availableColumns),function(info){return _c('b-list-group-item',{key:info.name,class:{
          'p-1': true,
          strong: info.visible,
          'cursor-default': info.locked,
          'cursor-pointer': !info.locked,
        },attrs:{"variant":info.locked
          ? _vm.activityVariant
          : info.visible
            ? _vm.activityVariant
            : _vm.themeVariant},on:{"click":function($event){$event.stopPropagation();return _vm.toggleVisible(info)}}},[_c('span',[(info.locked)?_c(VIcon,{class:`text-${_vm.activityVariant}`,attrs:{"small":""}},[_vm._v("mdi-lock")]):_vm._e(),_vm._v(" "+_vm._s(info.name)+" ")],1)])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }