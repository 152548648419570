import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-navbar',{staticClass:"p-0",style:(Object.assign(
    _vm.themeStyle('#titlebar'),
    { height: _vm.editorFullscreen ? 0 : 'auto' }
  )),attrs:{"id":"titlebar"}},[_c('b-row',{class:{
      [`text-${_vm.textVariant} w-100 d-flex justify-content-between`]: true,
    },style:({ height: _vm.editorFullscreen ? 0 : 'auto' }),attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"md":"auto","id":"title-area"}},[_c('b-row',{staticClass:"left-size",attrs:{"no-gutters":""}},[_c('b-col',{class:{
          'window-controls pl-3 pr-0': true,
          'mt-3': _vm.isMacOS,
        },style:(_vm.isMacOS ? 'padding-left: 20px!important' : ''),attrs:{"md":"auto"}}),_c('b-col',[_vm._t("title")],2)],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editorFullscreen),expression:"!editorFullscreen"}],attrs:{"md":""}},[_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"md":"auto","id":"left-gutter","align":"right"}},[_vm._t("actions")],2),_c('b-col',{attrs:{"md":""}},[_c('b-nav',{staticClass:"px-3",style:(_vm.themeStyle('#nav-areas')),attrs:{"id":"nav-areas","align":"right"}},[_vm._t("areas"),(_vm.isElectron && !_vm.isMacOS)?_c('b-nav-text',{attrs:{"id":"os-windows"}},[_c(VIcon,{class:{
                  [`ml-${_vm.area ? 1 : 3} mr-2 mt-1 align-top`]: true,
                },attrs:{"dark":_vm.dark,"small":""},on:{"click":_vm.window.controls.minimize}},[_vm._v("mdi-window-minimize")]),_c(VIcon,{staticClass:"mx-2 mt-1 align-top",attrs:{"dark":_vm.dark,"small":""},on:{"click":_vm.window.controls.maximize}},[_vm._v("mdi-window-maximize")]),_c(VIcon,{staticClass:"mx-2 mt-1 align-top",attrs:{"dark":_vm.dark,"small":""},on:{"click":_vm.window.controls.close}},[_vm._v("mdi-window-close")])],1):_vm._e()],2)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }