export default {
  'local': {
    env: {
      PROVIDER_ID: null,
      PROVIDER_UUID: null,
      CLIENT_ID: null,
    },
    'ink': {
      host: 'window://',
    },
    '@author': {
      source: 'https://marketplace.mythulu.com/service/service-author',
      host: 'window://',
      folder: 'INK_PROJECTS_ROOT',
    },
    '@beta-reader': {
      source: 'https://marketplace.mythulu.com/service/service-beta-reader',
      host: 'window://',
    },
    '@agent': {
      source: 'https://marketplace.mythulu.com/service/service-agent',
      host: 'window://',
    },
    '@editor': {
      source: 'https://marketplace.mythulu.com/service/service-editor',
      host: 'window://',
    },
    '@publisher': {
      source: 'https://marketplace.mythulu.com/service/service-publisher',
      host: 'window://',
    },
    '@account': {
      source: 'https://marketplace.mythulu.com/service/service-account',
      host: 'window://',
    },
  },
  // 'mythulu.ink': {
  //   env: {
  //     PROVIDER_ID: null,
  //     PROVIDER_UUID: null,
  //     CLIENT_ID: null,
  //   },
  //   'ink': {
  //     host: 'pubsub://marketplace.mythulu.com/',
  //   },
  //   '@account': {
  //     source: 'https://marketplace.mythulu.com/service-account',
  //     host: 'pubsub://marketplace.mythulu.com/',
  //     root: 'https://projects.mythulu.ink/',
  //   },
  //   '@author': {
  //     source: 'https://marketplace.mythulu.com/service-author',
  //     host: 'pubsub://marketplace.mythulu.com/',
  //     root: 'https://projects.mythulu.ink/',
  //   },
  // },
};
