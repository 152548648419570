import { each } from 'lodash';
import Vue from 'vue';
import store from '@/store';

export const Events = new Vue();


// function setupData(vm, data) {
//   each(data, (name) => {
//     Object.defineProperty(vm, name, {
//       get: () => vm.$store.state.data[name],
//     });
//   });
// }

function setupQueries(vm, queries) {
  each(Object.entries(queries), ([fullResource, nickname]) => {
    const [ area ] = fullResource.split('.', 1);
    Object.defineProperty(vm, nickname, {
      get: () => {
        let result = store.getters[`${area}/getQuery`](fullResource);
        if (result === undefined) {
          vm.$Service({
            nickname: fullResource,
            resource: fullResource,
            query: {},
          });
        } else if (result.loading) {
          result = [];
        }
        return result;
      },
    });
  });
}

/* Accepts events as `@areanname.serverEventName` or `@areaname/queryName`
 */
function setupEvents(vm, events) {
  if (typeof events !== 'object') {
    return;
  }

  // Cache of events to bound functions for automatic unsubscriptions
  let eventMap = {};
  for (let event in events) {
    eventMap[event] = events[event].bind(vm);
  }

  vm.$once('hook:beforeMount', () => {
    for (var name in eventMap) {
      Events.$on(name, eventMap[name]);
    }
    // console.log(vm._vnode.tag, 'ready', Object.keys(eventMap));
  });
  vm.$once('hook:beforeDestroy', () => {
    for (var name in eventMap) {
      Events.$off(name, eventMap[name]);
    }
    // Release cache
    eventMap = null;
  });
}


export default {
  install(Vue, options) {
    Vue.mixin({
      beforeCreate() {
        setupEvents(this, this.$options.events || {});
        setupQueries(this, this.$options.queries || {});
        // const events = Object.assign({}, queries, this.$options.events);
        // if (Object.keys(events).length) {
        //   set$Ready(this, setupEvents(this, events));
        // }
        // this.$Events = Events;
        this.$Service = ({ projectId=undefined, nickname, resource, query, force=false }) => {
          const areaMatch = resource.match(/^@(?<area>[\w_]+)\.(?<resource>[\w_]+)$/);
          if (areaMatch === null) {
            console.error(this.$vnode.tag, nickname, 'bad resource format, use @areaname.resourcename');
          } else {
            const { area, resource } = areaMatch.groups;
            const result = store.getters[`@${area}/getQuery`](nickname, projectId);
            if (force || result === undefined) {
              store.dispatch(`@${area}/query`, { projectId, nickname, resource, query, force });
            }
          }
        };
      },
    });
  }
};
