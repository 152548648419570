import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
  'widget': true,
  [`bg-${_vm.themeVariant}`]: _vm.widget.expanded,
  'm-3 rounded': !_vm.widget.expanded,
  [_vm.boxClass]: _vm.boxClass,
}},[_c('b-button',{class:{
      'w-100 source-header py-2 text-left font-weight-bold border-0': true,
      [`px-3 text-light bg-${_vm.activityVariant} border-`]: _vm.widget.expanded,
      [`px-0 text-${_vm.dark ? 'light' : 'muted'} bg-search${_vm.dark ? '-dark' : ''}`]: !_vm.widget.expanded,
    },attrs:{"disabled":_vm.debugLoading || !_vm.areaReady(_vm.area)},on:{"click":function($event){return _vm.setWidget({ name: _vm.name, expanded: !_vm.widget.expanded })}}},[(_vm.widget.icon)?_c(VIcon,{class:{
        'align-baseline mx-1': true,
        [`text-light`]: _vm.widget.expanded,
        [`text-${_vm.dark ? 'light' : 'muted'}`]: !_vm.widget.expanded,
      },staticStyle:{"margin-top":"2px"},attrs:{"dark":_vm.dark || _vm.widget.expanded}},[_vm._v(_vm._s(_vm.$t(_vm.widget.icon)))]):_vm._e(),(_vm.widget.showLabel)?_c('span',{class:{
        'align-top': true,
        [`text-light`]: _vm.widget.expanded,
        [`text-${_vm.dark ? 'light' : 'muted'}`]: !_vm.widget.expanded,
      },style:(`text-shadow: '0 1px 0 rgba(255, 255, 255, 0.5)`)},[_vm._v(" "+_vm._s(_vm.$t(_vm.widget.label))+" ")]):_vm._e()],1),(_vm.widget.expanded)?_c('div',{staticStyle:{"min-height":"50px"}},[_c(_vm.widgetComponent,{tag:"component"})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }