<template>
  <b-container fluid
    id="window"
    :theme="dark ? 'dark' : 'light'"
    :class="{
      [`p-0 h-100 text-${textVariant}`]: true,
      'fullscreen': editorFullscreen,
    }"
    :style="themeStyle('#window')"
  >
    <TitleBar>
      <template #title><slot name="title" /></template>
      <template #actions><slot name="actions" /></template>
      <template #areas><slot name="areas" /></template>
    </TitleBar>

    <b-row id="content-frame" class="h-100"
      :style="themeStyle('#content-frame')"
    >
      <b-col class="p-0 h-100">
        <slot name="content" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TitleBar from '@/components/TitleBar.vue';

export default {
  name: 'WindowLayout',
  computed: {
    ...mapState([
      'area',
    ]),
    ...mapGetters([
      'themeStyle',
      'textVariant',
      'areaVariant',
      'dark',
      'editorFullscreen',
    ]),
  },
  components: {
    TitleBar,
  },
};
</script>
