<template>
  <b-row
    no-gutters
    :class="Object.assign(
      {
        'navigator-item': true,
        'no-drag': delegated,
        delegated,
      },
      dataClasses
    )"
  >
    <b-col
      v-for="col in visibleColumns"
      :key="`${id}-${col.id}`"
      :cols="col.width"
      :class="Object.assign({
        'nav-cell': true,
        'nav-cell-with-icon': icon && col.id === 'meta.#',
        'small': small,
        [`bg-${variant}`]: hasHit(col),
        'text-light': hasHit(col) || parentActive || active,
        'text-left pl-1': col.align === 'left',
        'text-center': col.align === 'center',
        'text-right pr-1': col.align === 'right',
      })"
    >
      <v-icon v-if="icon && col.icon !== false && col.id === 'meta.#'"
        :small="small"
        :class="`text-${(parentActive || active) ? iconVariant : textVariant}`"
        style="margin-top: -3px"
      >{{ icon }}</v-icon>
      <span
        v-if="
          col.type !== null
            && typeof col.hoverable !== 'string'
            && (col.permanentWidget || edits[col.id] !== undefined)"
        @click.stop
      >
        <b-form-checkbox
          v-if="col.permanentWidget && (
            (col.type || typeof value(col)) === 'boolean'
          )"
          :size="col.small ? 'sm' : null"
          :checked="value(col)"
          class="no-drag"
          @change="commitEdit(col, $event)"
        />
        <span v-else-if="col.type === 'icon'"
          :class="{ 'hoverable-reveal': Boolean(col.hoverable) }"
        >
          <v-icon v-if="!col.component"
            :dark="dark"
            :small="col.small"
          >{{ col.permanentWidget }}</v-icon>
          <div v-else>
            <v-icon
              :dark="dark"
              :small="col.small"
              @click="triggerColumn(col)"
            >{{ col.permanentWidget }}</v-icon>
            <component :is="col.component"
              :id="`${source}-${id}-${col.id}-${col.component}`"
              :data="data"
              :columns="columns"
              :trigger="triggers[col.id]"
              :tag-id="tagId"
              :tag-type="tagType"
              :tag-path="tagPath"
              :delegated="delegated"
              :delegated-by="delegatedBy"
            />
          </div>
        </span>
        <b-form-input
          v-else
          autofocus
          size="sm"
          autocomplete="off"
          :value="edits[col.id]"
          :class="{
            [`d-inline-block text-${textVariant} no-drag cell-input`]: true,
            [`bg-deep-dark`]: dark,
            [`bg-deep-light`]: !dark,
            'small mt-1': col.small,
            'mt-0': !col.small,
          }"
          @input="updateEdit(col, $event)"
          @keydown.esc="clearEdit(col)"
          @keydown.enter="commitEdit(col)"
          @blur="commitEdit(col)"
        ></b-form-input>
      </span>
      <span v-else-if="col.hoverable || col.type !== null"
        :class="{ strong: active, small: col.small }"
        @dblclick="startEdit(col)"
      >
        <span v-if="col.percent && !delegated"
          @click.stop
        >
          <b-form-checkbox
            v-if="(col.type || typeof value(col)) === 'boolean'"
            :size="col.small ? 'sm' : null"
            :checked="value(col)"
            class="no-drag hoverable-reveal"
            @change="commitEdit(col, $event)"
          />
          <b-progress
            :variant="variant"
            :value="value(Object.assign({}, col, { id: col.hoverable }))"
            :max="100"
            :class="{
              'hoverable-default': true,
              [`bg-deep-${themeVariant}`]: true,
            }"
            style="height: 5px; margin: 10px 5px 0"
          />
        </span>
        <span v-else>
          {{ value(col) || ((parentActive || active) ? '—' : '') }}
        </span>
      </span>
    </b-col>
  </b-row>
</template>

<script>
import { get } from 'lodash';
import dateFormat from 'dateformat';
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    id: String,
    data: Object,
    source: String,
    tagId: { type: String, default: null, optional: true },
    tagType: { type: String, default: null, optional: true },
    tagPath: { type: String, default: null, optional: true },
    icon: String,
    small: { type: Boolean, default: false },
    columns: Array,
    variant: String,
    iconVariant: String,
    hits: { type: Object, default: () => ({}), optional: true },
    parentActive: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    delegated: { type: String, default: null, optional: true },
    delegatedBy: { type: String, default: null, optional: true },
  },
  data: () => ({
    edits: {},
    triggers: {},
  }),
  computed: {
    ...mapGetters([
      'dark',
      'textVariant',
      'themeVariant',
    ]),
    visibleColumns() {
      return this.columns.filter(c => c.visible);
    },
    dataClasses() {
      return Object.assign(
        {},
        ...this.columns.map(({ id, className }) => (
          className
            ? { [className]: this.value({ id }) }
            : {}
        ))
      );
    },
  },
  methods: {
    ...mapActions([
      'pushTag',
    ]),
    hasHit({ id: tag }) {
      return (this.hits || {})[tag] !== undefined;
    },
    value({ id: tag, percent, ...rest }) {
      const { format=null } = this.columns.find(c => c.id === tag) || {};
      const value = get(this.data, tag.split('.'));
      if (percent) {
        return (rest[tag] || {})[value] || value;
      }
      return format ? dateFormat(value, format) : value;
    },
    triggerColumn({ id }) {
      this.triggers = Object.assign(
        {},
        this.triggers,
        { [id]: +new Date() }
      );
    },
    startEdit({ id: tag }) {
      this._edit({ [tag]: this.value({ id: tag }) || '' });
    },
    updateEdit({ id: tag }, value) {
      this._edit({ [tag]: value });
    },
    clearEdit({ id: tag }) {
      this._edit({ [tag]: undefined });
    },
    commitEdit(col, value=undefined) {
      if (value !== undefined) {
        this.updateEdit(col, value);
      } else if (this.edits[col.id] === undefined) {
        // Blur can fire after Enter, but we should never send `undefined`
        return;
      }

      this._save(Object.assign({
        value: this.edits[col.id],
      }, col));
      this.clearEdit(col);
    },
    _edit(data) {
      this.edits = Object.assign({}, this.edits, data);
    },
    _save({ id: tag, annotator, freeze, value }) {
      this.pushTag({
        source:  this.source,
        item: Object.assign({}, this.data, {
          id: this.tagId || this.id,
          type: this.tagType || this.data.type,
        }),
        tag: (
          this.tagPath
          ? `meta.${this.tagPath}.${this.delegatedBy}.${this.id}.${tag}`
          : tag
        ),
        annotator: (
          this.tagPath
          ? undefined
          : annotator
        ),
        freeze,
        value,
      });
    },
  },
};
</script>
