<template>
  <b-list-group flush>
    <b-list-group-item
      v-for="commit in history"
      :key="commit.hash"
      :to="{}"
      @click="revert(commit)"
      class="p-1"
    >
      <strong>{{ commit.stats.modifiedFiles[0] }}</strong>
      <p class="small text-muted mb-0">
        <span v-if="commit.stats.words">
          {{ commit.stats.words }}
          {{ $tc('words', commit.stats.words) }}
        </span>
      </p>
      <p class="small text-muted mb-0">
        {{ dateFormat(commit.date, 'fullDate') }}
        {{ dateFormat(commit.date, 'longTime') }}
      </p>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import dateFormat from 'dateformat';
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  props: {
    sources: { type: Array, required: true },
    before: { type: Date, default: () => new Date(), optional: true },
    after: { type: Date, default: null, optional: true },
    quantity: { type: Number, default: 10, optional: true },
  },
  computed: {
    ...mapState([
      'area',
    ]),
    ...mapGetters([
      // 'activityVariant',
    ]),
    history() {
      return [
        {
          hash: '1234567',
          title: 'Write Nocturne/Scene 1',
          date: new Date(),
          stats: { words: 251, modifiedFiles: [
            'Flee The Burning Forest / Scene 1',
          ] },
        },
        {
          hash: 'abcdef0',
          title: 'Write Nocturne/Scene 1',
          date: new Date(),
          stats: { words: -23, modifiedFiles: [
            'Flee The Burning Forest / Scene 1',
          ] },
        },
      ];
    },
  },
  methods: {
    ...mapActions([
      'query',
    ]),
    dateFormat,
    revert({ hash }) {
      this.query({
        force: true,
        resource: `${this.area}.undo`,
        query: {
          projectId: this.$route.params.projectId,
          hash,
        },
      });
    },
  },
};
</script>
