var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`pt-1 small cursor-default bg-${_vm.themeVariant}`,staticStyle:{"padding-bottom":"1px"}},[_c('b-row',{class:`text-${_vm.textVariant} border-bottom border-deep-${_vm.themeVariant}`,staticStyle:{"margin-bottom":"-1px"},attrs:{"no-gutters":""}},_vm._l((_vm.visibleColumns),function(col){return _c('b-col',{key:`${col.order}-${col.id}-${col.sort}`,class:{
        //'cursor-pointer': true,
        'border-left-0 border-right-0': true,
        [`strong text-${_vm.textVariant} border border-${_vm.variant}`]: col.sort,
        'border-top-0': col.sort === 'asc',
        'border-bottom-0': col.sort === 'desc',
        'text-left pl-1': (col.headerAlign || col.align) === 'left',
        'text-center': (col.headerAlign || col.align) === 'center',
        'text-right pr-1': (col.headerAlign || col.align) === 'right',
      },staticStyle:{"margin-bottom":"-1px"},attrs:{"cols":col.width}},[_vm._v(_vm._s(col.name))])}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }