<template>
  <div :class="{
    'widget': true,
    [`bg-${themeVariant}`]: widget.expanded,
    'm-3 rounded': !widget.expanded,
    [boxClass]: boxClass,
  }">
    <b-button
      :disabled="debugLoading || !areaReady(area)"
      :class="{
        'w-100 source-header py-2 text-left font-weight-bold border-0': true,
        [`px-3 text-light bg-${activityVariant} border-`]: widget.expanded,
        [`px-0 text-${dark ? 'light' : 'muted'} bg-search${dark ? '-dark' : ''}`]: !widget.expanded,
      }"
      @click="setWidget({ name, expanded: !widget.expanded })"
    >
      <v-icon
        v-if="widget.icon"
        :dark="dark || widget.expanded"
        :class="{
          'align-baseline mx-1': true,
          [`text-light`]: widget.expanded,
          [`text-${dark ? 'light' : 'muted'}`]: !widget.expanded,
        }"
        style="margin-top: 2px;"
      >{{ $t(widget.icon) }}</v-icon>
      <span v-if="widget.showLabel"
        :style="`text-shadow: '0 1px 0 rgba(255, 255, 255, 0.5)`"
        :class="{
          'align-top': true,
          [`text-light`]: widget.expanded,
          [`text-${dark ? 'light' : 'muted'}`]: !widget.expanded,
        }"
      >
        {{ $t(widget.label) }}
      </span>
    </b-button>
    <div v-if="widget.expanded" style="min-height: 50px;">
      <component :is="widgetComponent"></component>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';

const components = {
  ChapterPlan: {
    render: (createElement) => createElement('div', {
      class: "mt-5 text-center rot-l1",
    }, 'Coming soon!'),
  },
  PocketBible: {
    render: (createElement) => createElement('div', {
      class: "mt-5 text-center rot-l1",
    }, 'Coming soon!'),
  },
  Goal: {
    render: (createElement) => createElement('div', {
      class: "mt-5 text-center rot-l1",
    }, 'Coming soon!'),
  },
  Calendar: {
    render: (createElement) => createElement('div', {
      class: "mt-5 text-center rot-l1",
    }, 'Coming soon!'),
  },
  Scraps: {
    render: (createElement) => createElement('div', {
      class: "mt-5 text-center rot-l1",
    }, 'Coming soon!'),
  },
};

export default {
  props: {
    area: String,
    name: String,
    boxClass: String,
  },
  data: () => ({
    debugLoading: false,
  }),
  computed: {
    ...mapGetters([
      'areaReady',
      'activityVariant',
      'textVariant',
      'themeVariant',
      'dark',
    ]),
    ...mapState({
      widget({}, { getWorkspace }){
        return get(getWorkspace(this.area), ['widgets', this.name]) || {};
      },
      widgetComponent() {
        return components[this.widget.component];
      },
    }),
  },
  methods: {
    ...mapActions([
      'setWidget',
    ]),
  },
}
</script>

<style lang="scss">
.widget {
  // transition: all 20ms linear;
}
</style>
