// import Vue from 'vue';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth, GithubAuthProvider, FacebookAuthProvider, GoogleAuthProvider
} from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
// import { firestorePlugin } from 'vuefire'

const app = initializeApp({
  apiKey: 'AIzaSyBBhr2P2SPndT2xGl8CAO-BjovQ8sYy70w',
  appId: '1:589983817645:web:a3db4be9995dd4f76c0b24',
  authDomain: 'mythulu-ink.firebaseapp.com',
  messagingSenderId: '589983817645',
  measurementId: 'G-R3MMK160R5',
  projectId: 'mythulu-ink',
  storageBucket: 'mythulu-ink.appspot.com',
});
// Vue.use(firestorePlugin);

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);

export const providers = {
  github: new GithubAuthProvider(),
  facebook: new FacebookAuthProvider(),
  google: new GoogleAuthProvider(),
  // twitter: new firebase.auth.TwitterAuthProvider(),
  // apple: new firebase.auth.OAuthProvider('apple.com'),
  // microsoft: new firebase.auth.OAuthProvider('microsoft.com'),
};

export const icons = {
  email: 'email',
  github: 'github',
  facebook: 'facebook',
  google: 'google',
  twitter: 'twitter',
  anonymous: 'incognito',
}
