<template>
  <div
    :source="source"
    :class="{
      [`navigator pull-in-left-init flex-grow-${dominant ? '1' : '0'}`]: true,
      'mx-3 mb-3': !expanded,
      [`bg-${themeVariant}`]: expanded,
    }"
    :style="Object.assign({},
      expanded ? themeStyle(`#navigator-${name}`) : {},
      themeStyle('.rounded'),
      { 'min-height': dominant || !expanded ? 'auto' : height }
    )"
  >
    <div :class="`h-100 d-flex flex-column justify-content-end`">
      <div>
        <SourceSelector v-if="showSource || sources.length > 1"
          :nav="name"
          :sources="sources"
          :selected="source"
          :expanded="expanded"
          :variant="activityVariant"
          :toggleable="toggleable"
          @select="setSource($event)"
          @expand="setExpanded($event)"
        />

        <Controls v-if="expanded"
          :nav="name"
          :source="source"
          :variant="activityVariant"
          :filter="userFilter"
          :filter-results="filterResults"
          :history-placement="historyPlacement"
          :columns-placement="columnsPlacement"
          @set-filter="userFilter = $event"
          @set-filter-results="filterResults = $event"
        />

        <Headers v-if="expanded"
          :nav="name"
          :source="source"
          :columns="columns"
          :variant="activityVariant"
        />
      </div>

      <List v-if="expanded"
        :nav="name"
        :source="source"
        :columns="columns"
        :scope="filter"
        :filter="userFilter"
        :filter-results="filterResults"
        :joins="joins"
        :variant="activityVariant"
        :scroll-frame="scrollFrame"
      />

      <ItemGenerator v-if="expanded"
        :nav="name"
        :source="source"
        :type-name="$t(`${source}-singular`)"
        :hotkeys="newItemHotkey"
        :factory="factory"
        :disabled="!adderEnabled"
      />
    </div>
  </div>
</template>

<script>
import { get, sortBy } from 'lodash';
import { mapState, mapGetters, mapActions } from 'vuex';
import SourceSelector from './SourceSelector.vue';
import Controls from './Controls.vue';
import Headers from './Headers.vue';
import List from './List.vue';
import ItemGenerator from './ItemGenerator.vue';

const VERSION = '2019-05-07';

export default {
  VERSION,
  name: 'Navigator',
  props: {
    name: String,
    showSource: { type: Boolean, default: true, optional: true },
    filter: Object,
    toggleable: { Boolean, default: true, optional: true },
    dominant: { type: Boolean, default: false, optional: true },
    newItemHotkey: { type: Array, default: () => [], optional: true },
    newItemEnabled: { type: Object, default: () => ({}), optional: true },
    scrollFrame: { type: String, default: '#frame' },
    factory: { type: Function, default: (source, meta) => meta },
  },
  data: () => ({
    userFilter: '',
    filterResults: true,
    height: '4em',
  }),
  computed: {
    ...mapGetters([
      'themeVariant',
      'activityVariant',
      'themeStyle',
    ]),
    ...mapState({
      expanded({}, { nav }) {
        return get(nav, [this.name, 'expanded']);
      },
      source({}, { nav }) {
        return get(nav, [this.name, 'source']);
      },
      sources({}, { nav }) {
        return get(nav, [this.name, 'sources']);
      },
      columns({}, { nav }) {
        return sortBy(get(nav, [this.name, 'columns', this.source]), ['order']);
      },
      joins({}, { nav }) {
        return get(nav, [this.name, 'joins']);
      },
      historyPlacement({}, { nav }) {
        return get(nav, [this.name, 'historyPlacement']);
      },
      columnsPlacement({}, { nav }) {
        return get(nav, [this.name, 'columnsPlacement']);
      },
      adderEnabled({}, { workspaceItem }) {
        const params = (workspaceItem || {}).params || {};
        return (
          this.newItemEnabled[this.source] === undefined
          || !!params[this.newItemEnabled[this.source]]
        );
      },
    }),
  },
  methods: {
    ...mapActions([
      'configureNav',
    ]),
    setExpanded(status) {
      this.configureNav({ name: this.name, expanded: status });
    },
    setSource(source) {
      source = source || this.source;
      if (source) {
        if (source !== this.source) {
          this.configureNav({
            name: this.name,
            source,
          });
        }
        this.$Service({
          nickname: source,
          resource: source,
          query: {},
        });
      }
    },
  },
  components: {
    SourceSelector,
    Controls,
    Headers,
    List,
    ItemGenerator,
  },
  created() {
    this.setSource();
  },
  updated() {
    this.setSource();
  },
};
</script>
