var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"m-0 w-100 h-100"},[_c('transition',{attrs:{"name":"pull-in-left"}},[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editorFullscreen),expression:"!editorFullscreen"}],class:`p-0 h-100 left-size`,staticStyle:{"overflow":"hidden"},attrs:{"md":"auto"}},[_c('div',{class:`d-flex flex-column h-100 bg-${_vm.themeVariant}`,style:(Object.assign({},
          _vm.themeStyle('.rounded'),
          _vm.themeStyle('#sidebar-left'),
        ))},[_vm._t("nav",function(){return [_vm._v("[NAV]")]})],2)])],1),_c('b-col',{staticClass:"p-0 h-100"},[_c('div',{staticClass:"h-100 mx-auto",style:(Object.assign({},
        _vm.themeStyle('#content'),
        { width: _vm.workspace.width || '900px' }
      ))},[_vm._t("content",function(){return [_vm._v("[CONTENT]")]})],2)]),_c('transition',{attrs:{"name":"pull-in-right"}},[_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editorFullscreen),expression:"!editorFullscreen"}],staticClass:"p-0 h-100 right-size",staticStyle:{"overflow":"hidden auto"},attrs:{"md":"auto"}},[_c('div',{staticClass:"d-flex flex-column h-100",style:(_vm.themeStyle('#sidebar-right'))},[_vm._t("sidebar",function(){return [_vm._v("[SIDEBAR]")]})],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }