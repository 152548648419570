<template>
  <div :class="`text-${textVariant}`">
    <div class="text-center">
      <b-form-group>
        <b-form-radio-group
          id="report-type"
          name="type"
          buttons
          button-variant="outline-mango"
          v-model="topic"
          :options="topics"
        />
      </b-form-group>
    </div>
    <b-form-group
      label="Location:"
      label-for="report-url"
      description="This is the page you're viewing."
    >
      <b-input
        name="url"
        id="report-url"
        v-model="url"
        size="sm"
        disabled
        :class="`bg-search${dark ? '-dark' : ''} text-${textVariant}`"
      />
    </b-form-group>
    <b-form-group
      label="Subject:"
      label-for="report-subject"
      description="This is the page you're viewing."
    >
      <b-input
        name="title"
        id="report-subject"
        v-model="title"
        size="md"
        :class="`bg-search${dark ? '-dark' : ''} text-${textVariant}`"
      />
    </b-form-group>
    <b-form-group
      label="Description:"
      description="A short description."
    >
      <Editor
        :item="item"
        target-area="@account"
        format-type="report"
        :use-store="false"
      />
    </b-form-group>

    <b-button
      block
      variant="outline-mango"
      @click="showRecent = !showRecent"
    >{{ $t('Show recent activity') }}</b-button>
    <b-row v-show="showRecent" class="mt-2">
      <b-col>
        <h5 class="text-center">{{ $t('Queries') }}</h5>
        <b-list-group>
          <b-list-group-item
            v-for="(log, i) in recentInputs"
            :key="i"
            :class="{
              'd-flex justify-content-start align-items-center': true,
              [`bg-search${dark ? '-dark' : ''}`]: true,
            }"
          >
            📍
            <b-badge
              :variant="activityVariant(log)"
            >{{ log.service }}</b-badge>
            {{ log.resource.split('.', 1)[0] }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <h5 class="text-center">{{ $t('Logs') }}</h5>
        <b-list-group>
          <b-list-group-item
            v-for="(log, i) in recentLogs"
            :key="i"
            :class="{
              'd-flex justify-content-start align-items-center': true,
              [`bg-search${dark ? '-dark' : ''}`]: true,
            }"
          >
            {{ log.marker }}
            <b-badge
              :variant="activityVariant(log)"
            >{{ log.service }}</b-badge>
            {{ log.resource.replaceAll(`${log.service}.`, '') }}
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      showRecent: false,
      report: {
        type: 'report',
      },
      url: this.$route.path,
      title: null,
      topic: 'Not Working',
      topics: [
        'Not Working',
        'Suggestion',
      ],
      body: null,
    };
  },
  computed: {
    ...mapState([
      'providers',
    ]),
    ...mapGetters([
      'dark',
      'textVariant',
      'getArea',
    ]),
    recentInputs: () => window.local.inputs(),
    recentLogs: () => window.local.logs(),
    item() {
      return Object.assign({}, this.report, {
        title: this.title,
        url: this.url,
        topic: this.topic,
        body: this.report.text,
      });
    },
  },
  methods: {
    activityVariant({ service }) {
      return this.getArea(service).variant;
    },
  },
};
</script>

<style lang="scss">
.report .ProseMirror {
  p {
    margin: 0;
  }
  > ul {
    margin: 0;
  }
}
</style>
