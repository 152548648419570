import { square } from './square' ;

export const light = {
  ...square,
  '#titlebar': {
    'background-color': 'var(--light)',
    'border-bottom': '1px solid var(--deep-light)',
  },
  '#content-frame': {
    'margin': '-58px 0 0 0',
    'padding-top': '58px',
  },
  '#content': {
    'border-left': '1px solid var(--off-light)',
    'border-right': '1px solid var(--off-light)',
  },
  '#sidebar-left': {
    'border-right': '1px solid var(--off-light)',
  },
  '#navigator-pinned': {
    'border-left': '1px solid var(--off-light)',
  },
  '.nav-area': {
    'border-radius': '2px',
    'margin': '0 2px',
  },
  '.nav-area-active': {
    'border': '1px solid var(--deep-light)',
    'margin-top': '-1px',
  },
  '.nav-task': {
    'border-radius': '2px',
    'margin': '4px',
  },
  '.nav-task-active': {
    'border': '1px solid var(--deep-dark)',
    'margin': '3px',
  },
};
export const dark = {
  ...square,
  '#titlebar': {
    'background-color': 'var(--dark)',
    'border-bottom': '1px solid var(--deep-dark)',
  },
  '#content-frame': {
    'margin': '-58px 0 0 0',
    'padding-top': '58px',
  },
  '#content': {
    'border-left': '1px solid var(--off-dark)',
    'border-right': '1px solid var(--off-dark)',
  },
  '#sidebar-left': {
    'border-right': '1px solid var(--off-dark)',
  },
  '#navigator-pinned': {
    'border-left': '1px solid var(--off-dark)',
  },
  '.nav-area': {
    'border-radius': '2px',
    'margin': '0 2px',
  },
  '.nav-area-active': {
    'border': '1px solid var(--deep-dark)',
    'margin-top': '-1px',
  },
  '.nav-task': {
    'border-radius': '2px',
    'margin': '4px',
  },
  '.nav-task-active': {
    'border': '1px solid var(--deep-dark)',
    'margin': '3px',
  },
};
