const CACHE = 'caches' in self ? caches.open('oauth') : undefined;
const request = new Request('/token');

export async function recoverToken() {
  const cache = await CACHE;
  const response = await cache.match(request);
  if (response) {
    return await response.text();
  }
}

export async function setToken(token) {
  const cache = await CACHE;
  await cache.put(request, new Response(Buffer.from(token).toString('base64')));
}
