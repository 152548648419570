var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    [`navigator pull-in-left-init flex-grow-${_vm.dominant ? '1' : '0'}`]: true,
    'mx-3 mb-3': !_vm.expanded,
    [`bg-${_vm.themeVariant}`]: _vm.expanded,
  },style:(Object.assign({},
    _vm.expanded ? _vm.themeStyle(`#navigator-${_vm.name}`) : {},
    _vm.themeStyle('.rounded'),
    { 'min-height': _vm.dominant || !_vm.expanded ? 'auto' : _vm.height }
  )),attrs:{"source":_vm.source}},[_c('div',{class:`h-100 d-flex flex-column justify-content-end`},[_c('div',[(_vm.showSource || _vm.sources.length > 1)?_c('SourceSelector',{attrs:{"nav":_vm.name,"sources":_vm.sources,"selected":_vm.source,"expanded":_vm.expanded,"variant":_vm.activityVariant,"toggleable":_vm.toggleable},on:{"select":function($event){return _vm.setSource($event)},"expand":function($event){return _vm.setExpanded($event)}}}):_vm._e(),(_vm.expanded)?_c('Controls',{attrs:{"nav":_vm.name,"source":_vm.source,"variant":_vm.activityVariant,"filter":_vm.userFilter,"filter-results":_vm.filterResults,"history-placement":_vm.historyPlacement,"columns-placement":_vm.columnsPlacement},on:{"set-filter":function($event){_vm.userFilter = $event},"set-filter-results":function($event){_vm.filterResults = $event}}}):_vm._e(),(_vm.expanded)?_c('Headers',{attrs:{"nav":_vm.name,"source":_vm.source,"columns":_vm.columns,"variant":_vm.activityVariant}}):_vm._e()],1),(_vm.expanded)?_c('List',{attrs:{"nav":_vm.name,"source":_vm.source,"columns":_vm.columns,"scope":_vm.filter,"filter":_vm.userFilter,"filter-results":_vm.filterResults,"joins":_vm.joins,"variant":_vm.activityVariant,"scroll-frame":_vm.scrollFrame}}):_vm._e(),(_vm.expanded)?_c('ItemGenerator',{attrs:{"nav":_vm.name,"source":_vm.source,"type-name":_vm.$t(`${_vm.source}-singular`),"hotkeys":_vm.newItemHotkey,"factory":_vm.factory,"disabled":!_vm.adderEnabled}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }