import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 d-flex flex-column"},[_c('b-row',{staticClass:"justify-content-center",staticStyle:{"margin-top":"280px"},attrs:{"no-gutters":""}},_vm._l((_vm.orderedAreas),function(Area,index){return _c('b-col',{key:Area.area,class:`col-md-auto d-flex justify-content-top mr-${Area.shy ? 0 : 3}`,staticStyle:{"font-size":"1.2rem"}},[(index > 0 && !Area.shy)?_c('span',{staticClass:"ml-5"},[_vm._v(" • ")]):_vm._e(),(!(_vm.$store.state[Area.area] || {}).ready)?_c('span',[_c('router-link',{class:{
            'text-center d-inline-block ml-5 cursor-pointer': true,
            'text-decoration-none text-neutral': true,
          },attrs:{"to":{ name: 'home' },"disabled":""}},[_c('div',[_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.$t(Area.area)))])]),_c(VIcon,{class:`slow-rock text-${Area.variant}`,staticStyle:{"font-size":"55px"}},[_vm._v("mdi-timer-sand")])],1)],1):_vm._e(),(!Area.shy && (_vm.$store.state[Area.area] || {}).ready)?_c('span',[_c('router-link',{class:{
            'text-center d-inline-block ml-5 cursor-pointer': true,
            [`text-decoration-none text-${_vm.textVariant}`]: true
          },attrs:{"to":(_vm.$store.state[Area.area] || {}).ready ? { name: Area.area } : {}}},[_c('div',[_c('span',{class:`border-bottom border-${Area.variant}`},[_vm._v(_vm._s(_vm.$t(Area.area)))])]),_c(VIcon,{class:{
              [`area-link text-${Area.variant}`]: true,
              'opacity-2': !_vm.$store.state[Area.area].ready,
            },staticStyle:{"font-size":"55px"}},[_vm._v(_vm._s(Area.icon))])],1)],1):_vm._e()])}),1),_c('div',{staticClass:"mt-auto mb-5 text-center"},[_c('code',[_vm._v("[main] v"+_vm._s(_vm.VERSION)+" ink © Mythulu Inc. ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }