/* Ink Well repository */
import { map, pickBy, each, isObject } from 'lodash';
import store from '@/store';
import { Events } from '@/events';
import { getApps, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
// import { PubSub } from '@google-cloud/pubsub';
// const pubSubClient = new PubSub();

/**
 * Map of service names to promises that resolve to send functions for that
 * service.
 */
export const services = {};

Events.$on('ink.connect', (state) => {
  store.dispatch('loadState', state);
  store.dispatch('release');
});


function enrichPayload(message) {
  return Object.assign(
    message || {},
    {
      uid: store.getters.userId,
      token: window.account.token(),
    },
  );
}

async function authenticateAppUser(app) {
  const auth = getAuth();
  const { currentUser } = auth;

  if (currentUser) {
    const appAuth = getAuth(app);
    try {
      await appAuth.updateCurrentUser(currentUser);
    } catch (error) {
      console.error('Error authenticating with external credential:', error);
    }
  } else {
    throw new Error('No current user to authenticate with');
  }
}

function validateResource(serviceName, fullResource) {
  const [ pluginName ] = fullResource.split('.', 1);
  const valid = (pluginName === serviceName);
  if (!valid) {
    console.error(`Cannot send to '${fullResource}' from '${serviceName}'`);
  }
  return valid;
}

function forward(serviceName, send) {
  return (fullResource, message) => {
    if (!validateResource(serviceName, fullResource)) {
      throw new Error(`Invalid resource '${fullResource}' for service '${serviceName}'`);
    }
    const payload = enrichPayload(message);
    send(fullResource, payload);
  };
}


async function getRemoteFirebase(config, uniqueLabel) {
  const existingApp = getApps().find((app) => app.name === uniqueLabel);
  if (existingApp) {
    await authenticateAppUser(existingApp);
    return existingApp;
  }
  const app = initializeApp(config, uniqueLabel);
  await authenticateAppUser(app);
  return app;
}

function purgeRemoteFirebase(uniqueLabel) {
  const existingApp = getApps().find((app) => app.name === uniqueLabel);
  if (existingApp) {
    existingApp.delete();
  }
}

function getCloudFunctionName(serviceName, resource) {
  return `${serviceName.replace(/^@/, '')}_${resource}`;
}


const pipes = {
  window: (providerId, serviceName, config, resolve, reject) => {
    if (window[providerId] === undefined) {
      reject(`Window does not expose '${providerId}'!`);
    }
    window[providerId].on(
      serviceName,
      (resource, message) => receive(providerId, resource, message)
    );
    resolve(forward(serviceName, (fullResource, message) => {
      window[providerId].send(fullResource, enrichPayload(message));
    }));
  },
  functions: (providerId, serviceName, { firebase={} }, resolve, reject) => {
    const label = `${providerId}:${serviceName}`;
    getRemoteFirebase(firebase, label);
    resolve(forward(serviceName, async (fullResource, message) => {
      const providerApp = await getRemoteFirebase(firebase, label);
      const providerFunctions = getFunctions(providerApp);
      const [_, resource] = fullResource.split('.', 2);
      const name = getCloudFunctionName(serviceName, resource);
      const cloudFunction = httpsCallable(providerFunctions, name);
      try {
        const result = await cloudFunction(enrichPayload(message));
        return result;
      } catch (error) {
        console.error(`cloud plugin pipe ${serviceName} ${name}`, error);
        return {};
      }
    }));
  },
  wss: (providerId, serviceName, { host }, resolve, reject) => {
    reject(`Unsupported pipe 'wss'!`);
    // const ws = new WebSocket(host);
    // ws.onopen = () => {
    //   resolve((...args) => ws.send(...args));
    // };
    // ws.onclose = () => {
    //   store.dispatch('serviceLost', { providerId, name: serviceName });
    //   // const interval = setInterval(() => connect(providerId, serviceName, interval), 1000);
    // };
    // ws.onmessage = (event) => {
    //   const [ fullResource ] = event.data.split(':', 1);
    //   const messageString = event.data.substring(fullResource.length + 1);
    //   receive(providerId, fullResource, JSON.parse(messageString));
    // };
  },
  pubsub: (providerId, serviceName, { host }, resolve, reject) => {
    reject(`Unsupported pipe 'pubsub'!`);
    // const attrs = {
    //   uid: store.state.user.uid,
    //   providerId,
    // };
    // const [ domain ] = host.split('://', 1);
    // const topic = `${domain}-${serviceName}-${store.state.user.uid}`;
    // functions.pubsub.topic(topic).onPublish((message) => {
    //   receive(providerId, message.json);
    // });
    // resolve(
    //   (resource, message) => pubSubClient
    //     .topic(topic)
    //     .publish(Buffer.from(message), { ...attrs, resource })
    //     .catch(console.error)
    // );
  },
};

/**
 * Sets a promise for a send function for each provider.
 */
export default function startServices() {
  each(store.state.providers, (manifest, providerId) => {
    const names = Object.keys(manifest).filter(
      name => name !== 'env' && services[name] === undefined
    );
    const promises = Object.assign(
      {},
      ...map(names, name => ({
        [name]: connect(providerId, name, manifest[name]),
      })),
    );
    Object.assign(services, pickBy(promises, v => v !== null));
  });
}

/**
 * Returns a promise for `providerId` that resolves to the dispatch function for
 * that provider's api.
 */
function connect(providerId, name, config, reconnect=false) {
  console.log(providerId, 'service login', name);
  if (!reconnect && services[name] !== undefined) {
    return null;
  }
  const { host } = config;
  const [ bindType ] = host.split('://');
  const bind = pipes[bindType];
  return new Promise((resolve, reject) => {
    bind(providerId, name, config, resolve, reject);
  }).then(async (send) => {
    store.dispatch('serviceStarted', { providerId, name });
    send(`${name}.connect`);
    return send;
  }).catch((e) => {
    console.error(providerId, 'service unavailable', name, `\n${e}`);
    return () => undefined;
  });
}

/**
 * Global receiver handler for service response messages.  Object-type messages
 * are emitted on the `Events` bus.  Messages with a `nickname` or `partial`
 * prop are also dispatched to the store's `setQuery()` action.
 */
function receive(providerId, fullResource, message) {
  if (message.ERROR) {
    console.error(providerId, fullResource, message);
    return;
  }

  try {
    switch (fullResource) {
      case 'ink.env':
        store.dispatch('setProviderEnv', { providerId, env: message });
        break;
      default:
        if (isObject(message)) {
          Events.$emit(fullResource, message);
          if (message.nickname || message.partial) {
            store.dispatch('setQuery', message);
          }
        }
    }
  } catch (e) {
    console.error('component event error', fullResource, message, e);
  }
}
