/**
 * Provides a core `ink` service and an `expose()` function for publishing
 * services on the electron context bridge, bussed by ipcMain/ipcRenderer.
 * @namespace window
 */

import { isObject, omit } from 'lodash';

const ipcRenderer = null;
let accessToken = null;
const recentInputs = [];
const recentLogs = [];


export const cloud = {
  /**
   * Retrieve services map.
   * @memberof window.cloud
   * @returns {object} Map of service names to api object.
   */
  services: () => cloud.send('ink.services', []),

  /**
   * Route a message object to a provider api endpoint.
   * @memberof window.cloud
   * @argument {string} fullResource A dotted `@some-plugin.endpointName` label.
   * @argument {Any} message Often an `Object`, the message is serialized and
   * sent to the api endpoint handler specified by `fullResource`.
   */
  send: async (fullResource, message) => {
    const [ service, resource ] = fullResource.split('.', 2);
    console.info('📍', 'cloud', fullResource, isObject(message)
      ? omit(message, ['nickname', 'root'])
      : message
    );

    const messageString = JSON.stringify(message);
    recentInputs.unshift({ service, resource, messageString });
    recentInputs.length = 10;

    /**
     * @memberof window.cloud.send
     * @argument {string} service The name of a `cloud`-hosted service.
     * @argument {string} resource An api endpoint name hosted by the service.
     * @argument {string} json Serialized message for transport to the api.
     * @fires @someplugin
     */
    send(service, resource, messageString);
  },

  /**
   * Register a handler callback `hook` for all secure replies from the named
   * `service` label.
   * @memberof window.cloud
   * @argument {string} service The service name, e.g., `@author`, `@account`,
   * etc.
   * @argument {function} hook The callback accepting the apiName and response
   * message.
   */
  on: (service, hook, name) => {
    /**
     * Registers `hook` for replies from `service`.
     * @memberof window.cloud.on
     * @event @someservice
     */
    ipcRenderer.on(service, async (event, resource, messageString, marker) => {
      recentLogs.unshift({ service, resource, messageString, marker });
      recentLogs.length = 10;

      const message = await new Promise((resolve) => {
        try {
          resolve(JSON.parse(messageString));
        } catch (e) {
          resolve(e);
        }
      });
      const msg = isObject(message)
        ? omit(message, ['nickname', 'root'])
        : message
        ;
      const type = {
        '⚡︎': 'log',
        '📍': 'info',
        '❓': 'warn',
        '❌': 'error',
        '💥': 'error',
        '✅': 'info',
      }
      console[type[marker] || 'log'](
        marker || '→',
        'cloud',
        message.nickname || resource,
        msg
      );
      if (type[marker] !== 'error') {
        hook(resource, message);
      }
    });
  },

  /**
   * Recent logs
   */
  inputs: () => recentInputs.filter(() => true),

  /**
   * Recent logs
   */
  logs: () => recentLogs.filter(() => true),
}


export const device = {
  /**
   * Ask device to check and install dependencies if missing.
   * @memberof window.device
   */
  checkDependencies: () => {
    /**
     * @memberof window.device.checkDependencies
     * @fires Dependencies:check
     */
  },

  /**
   * Fetch cached device UUID, or a fresh one if the UUID is not yet cached.
   * @async
   * @memberof window.device
   * @returns {string} The device UUID
   */
  uuid: async () => await getId(),

  /**
   * Determine if the device is macOS.
   * @memberof window.device
   * @returns {boolean}
   */
  isMacOS: () => window.navigator.userAgent.includes('macintosh'),

  /**
   * Determine if the device is Windows.
   * @memberof window.device
   * @returns {boolean}
   */
  isWindows: () => window.navigator.userAgent.includes('windows'),

  /**
   * Determine if the application is running on Electron.
   * @memberof window.device
   * @returns {boolean} Always true
   */
  isElectron: () => false,

  /**
   * Determine if the application is running on the web.
   * @memberof window.device
   * @returns {boolean} Always false
   */
  isWeb: () => true,

  /**
   * (Not implemented on web) Ask device to open a target folder path.
   * @memberof window.device
   * @argument {string} path Folder path to open.
   */
  openFolder: (path) => null,

  /**
   * Ask device to check the WelcomeWindow status, and display it if it has
   * not yet appeared for this device.
   * @memberof window.device
   */
  welcome: () => {
    /**
     * @memberof window.device.welcome
     * @fires WelcomeWindow:check
     */
  },
};


export const account = {
  token: () => accessToken,
};


export const context = {
  /**
   * Sets a data object as the subject of the context menu.
   * @memberof window.context
   */
  set: (context) => null,

  /**
   * Register as a responder to `MainWindow:context-action` on the bridge,
   * using a `callback` that accepts a selected menu action and the context
   * object that was the subject when it was selected.
   *
   * Interpreting the action is the job of the callback.
   * @memberof window.context
   */
  listen: (callback) => null,
};
