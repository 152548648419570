import { without } from 'lodash'
import i18n from '@/i18n'
import store from '@/store'
import router from '@/router'

const plugins = {};  // { '@plugin': 'loadedByProviderId' }
const providers = {};  // 'providerId': { '@plugin': Promise<Component> }

export default async function loadProvider(id) {
  if (providers[id] !== undefined) {
    return;
  }

  const providerPlugins = {};
  providers[id] = plugins;

  const manifest = store.getters.providers[id];
  if (manifest === undefined) {
    return new Promise((resolve, reject) => reject(`${id} manifest unknown`));
  }

  // Extract manifest for base `ink` service and allowed plugins as `configs`.
  // Remove plugin names that are already loaded (regardless of initial origin).
  const { env: envConfig={}, ink, ...configs } = manifest;
  const pluginNames = Object.keys(configs);
  const newNames = without(pluginNames, ...Object.keys(plugins));
  console.log(id, 'provider login', newNames, envConfig);

  // Mark plugin frontends as loaded, to prevent multiple loads
  Object.assign(plugins, ...newNames.map(name => ({ [name]: id })));

  // Import and attach plugins to their provider registry.
  Object.assign(providerPlugins, ...newNames.map(name => ({
    [name]: loadPlugin(
      id,
      name,
      import(/* webpackChunkName: "@[request]" */ `/${process.env.VUE_APP_PLUGINS_DIR}/${name}/Area.vue`)
    ).then(Plugin => attach(id, Plugin))
     .catch(console.error),
  })));

  // Activate provider id
  store.dispatch('enableProvider', id);
}

// Module promise => Component promise
function loadPlugin(providerId, name, m) {
  return m
    .then((m) => {
      console.log(providerId, 'plugin loaded', m.default.name || m.default.Area.area);
      return m.default;
    })
    .catch((e) => {
      console.error(providerId, 'plugin error', name, e);
      return { Area: { area: name } };
    })
    ;
}

/**
 * Adds a component to the `providerId`'s area mapping.  The component must
 * include an `Area` prop containing the plugin's area-type configuration.
 *
 * - `Area.area`: namespace for data store, i18n, and router entries
 * - `Area.store`: vuex store module
 * - `Area.i18n`: vue-i18n messages object containing multiple locales
 * - `Area.activities`: vue-router child routes mounted within the plugin
 * - `Area.icon`: supported icon name, such as `mdi-transit-connection`
 * - `Area.variant`: supported bootstrap color name for shading the plugin's ui
 *
 * If required, a plugin's `store` module should provide an action called
 * `release` that unblocks the UI after being bound to the platform.
 */
function attach(providerId, Plugin) {
  const {
    store: areaStore={},
    i18n: messages,
    ...config
  } = Plugin.Area;

  const {
    area,
    icon = 'mdi-transit-connection',
    variant = 'primary',
    activities: children=[],
  } = config;
  console.log(providerId, 'plugin attach', area, config);

  const { [providerId]: areas={} } = providers;
  areas[area] = Plugin;
  providers[providerId] = areas;

  const path = area.replace('@', '/');

  store.registerModule(area, areaStore);
  i18n.setLocaleMessage(i18n.locale, Object.assign(
    {},
    i18n.messages[i18n.locale],
    (messages || {})[i18n.locale] || {}
  ));
  router.addRoute({
    path,
    props: true,
    component: Object.assign({}, Plugin, {
      Area: { store: undefined, i18n: undefined },
    }),
    meta: { area },
    children,
  });
  store.dispatch(`${area}/query`, { resource: 'retrieve' });
  store.dispatch('enableActivities', Object.assign(
    {},
    config,
    { path, providerId, icon, variant },
  ));
  store.dispatch(`${area}/release`);

  return Plugin;
}
