import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[(_vm.filter)?_c('label',{staticClass:"m-0",attrs:{"for":`nav-${_vm.nav}-user-filter`}},[_c(VIcon,{class:`text-${_vm.variant}`,staticStyle:{"margin-top":"-2px"},attrs:{"small":""},on:{"click":_vm.clearFilter}},[_vm._v("mdi-close-circle-outline")])],1):_c(VIcon,{class:`text-${_vm.variant}`,staticStyle:{"margin-top":"-2px"},attrs:{"dark":_vm.dark}},[_vm._v("mdi-magnify")])],1),_c('b-col',[_c('b-input',{class:{
        [`slim rounded border-0 text-${_vm.textVariant}`]: true,
        'bg-search': !_vm.dark,
        'bg-search-dark': _vm.dark,
      },attrs:{"id":`nav-${_vm.nav}-user-filter`,"placeholder":"Search...","autocomplete":"off","size":"sm"},model:{value:(_vm.filterAccessor),callback:function ($$v) {_vm.filterAccessor=$$v},expression:"filterAccessor"}})],1),_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c(VIcon,{class:`text-${_vm.filterResults ? _vm.variant : _vm.textVariant}`,attrs:{"title":_vm.$tc('nav-filter-enabled', Number(_vm.filterResults) + 1)},on:{"click":_vm.toggleFilterResults}},[_vm._v("mdi-filter")])],1),_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c(VIcon,{staticClass:"cursor-pointer",attrs:{"id":`nav-${_vm.nav}-columns`,"dark":_vm.dark,"title":_vm.$t('nav-sort-options')}},[_vm._v("mdi-sort-variant")])],1),_c('b-col',{staticClass:"text-center",attrs:{"cols":"1"}},[_c(VIcon,{attrs:{"id":`nav-${_vm.nav}-history`,"dark":_vm.dark,"title":_vm.$tc('undo', Number(_vm.filterResults) + 1)}},[_vm._v("mdi-history")])],1),_c('b-popover',{attrs:{"target":`nav-${_vm.nav}-history`,"triggers":"focus click","variant":_vm.dark ? _vm.variant : _vm.themeVariant},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" History ")]},proxy:true}])},[_c('div',{staticClass:"controls columns-config"},[_c('HistoryList',{attrs:{"sources":[_vm.source]}})],1)]),_c('b-popover',{attrs:{"target":`nav-${_vm.nav}-columns`,"triggers":"focus click","variant":_vm.dark ? _vm.variant : _vm.themeVariant},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Display ")]},proxy:true}])},[_c('div',{staticClass:"controls columns-config"},[_c('ColumnPicker',{attrs:{"nav":_vm.nav,"source":_vm.source,"columns":_vm.columns}}),_c('HierarchyPicker',{attrs:{"nav":_vm.nav,"source":_vm.source,"folds":_vm.folds,"idParam":_vm.foldParam}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }