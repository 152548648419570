<template>
  <b-button-toolbar>
    <b-button-group :class="{
      'd-flex flex-grow-1 rounded': true,
      [`px-0 bg-search${dark ? '-dark' : ''}`]: !expanded,
      [`mx-0`]: expanded,
    }">
      <SourceSelectorButton
        v-for="source in sources"
        :key="source"
        :nav="nav"
        :text="$t(getName(source))"
        :source="source"
        :active="source === selected"
        :expanded="expanded"
        :icon="$t(`${getName(source)}-icon`)"
        :variant="variant"
        :toggleable="toggleable"
        @select="$emit('select', $event)"
        @expand="$emit('expand', $event)"
      />
    </b-button-group>
  </b-button-toolbar>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SourceSelectorButton from './SourceSelectorButton.vue';

export default {
  props: {
    nav: String,
    sources: Array,
    selected: String,
    expanded: { type: Boolean, default: true },
    toggleable: { type: Boolean, default: true },
    variant: String,
  },
  computed: {
    ...mapGetters([
      'dark',
    ]),
    ...mapState({
      getName({}, { nav }) {
        return source => nav[this.nav].names[source] || source;
      },
    })
  },
  components: {
    SourceSelectorButton,
  },
};
</script>
