<template>
  <div class="h-100">
    <div v-if="ready" class="h-100">
      <transition name="intro" appear>
        <Window />
      </transition>
    </div>
    <div v-else :class="`h-100 bg-${themeVariant}`" style="overflow-y: auto">
      <Onboarding
        :relay-auth-to-electron="isAuthMode"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { collection, getDoc, doc } from 'firebase/firestore';
import { auth, firestore } from '@/firebase';
import Window from '@/components/Window.vue';
import Onboarding from '@/components/Onboarding.vue';
import store from '@/store';
import { recoverToken } from '@/token';

const whitelist = collection(firestore, 'whitelist');

/**
 * Root application.
 * @displayName App
 */
export default {
  /**
   * @type {Object}
   */
  events: {
    /**
     * Loads state object given from `@account` provider.
     * @event @account.retrieve
     * @returns {object}
     */
    '@account.retrieve': state => store.dispatch('loadState', {
      '@account': state,
    }),
  },
  computed: {
    ...mapState([
      'ready',
      // 'authenticated',
    ]),
    ...mapGetters([
      'themeVariant',
    ]),

    /**
     * @returns {boolean} Whether or not `?auth` is used in the url.
     * */
    isAuthMode() {
      return this.$route.query.auth !== undefined;
    },
    isElectron: () => window.device.isElectron(),
  },
  methods: {
    ...mapActions([
      'setUser',
      'logOut',
      'release',
      'updateWaitlist',
      'updateLaunchNotice',
      'setAuthIssue',
    ]),
  },
  components: {
    Window,
    Onboarding,
  },
  async beforeCreate() {
    // await uuid();
    window.addEventListener('beforeunload', async (e) => {
      await store.dispatch('saveState');
      delete e['returnValue'];
    });
    auth.onAuthStateChanged(async (user) => {
      const u = (user === null || !Object.keys(user).length) ? null : user;
      this.setUser(user);
      if (u === null) {
        console.log('user', false);
        return;
      }

      if (!this.isElectron) {
        setTimeout(async () => {
          const token = await recoverToken();
          if (!token) {
            console.log('user', token);
            // this.logOut();
            return;
          }
        }, 1000);
      }

      console.log('user', true);
      const ref = await getDoc(doc(whitelist, user.email));
      const exists = ref.exists();
      console.log('whitelist', exists);
      if (exists) {
        this.release();
      } else {
        this.updateWaitlist();
        this.updateLaunchNotice();
        this.setAuthIssue({
          code: 'ink/invitation',
          message: `Ink is currently in private beta.`,
        });
      }
    });
  },
};
</script>
