<template>
  <div
    :class="`pt-1 small cursor-default bg-${themeVariant}`"
    style="padding-bottom: 1px;"
  >
    <b-row
      no-gutters
      :class="`text-${textVariant} border-bottom border-deep-${themeVariant}`"
      style="margin-bottom: -1px;"
    >
      <b-col
        v-for="col in visibleColumns"
        :key="`${col.order}-${col.id}-${col.sort}`"
        :cols="col.width"
        :class="{
          //'cursor-pointer': true,
          'border-left-0 border-right-0': true,
          [`strong text-${textVariant} border border-${variant}`]: col.sort,
          'border-top-0': col.sort === 'asc',
          'border-bottom-0': col.sort === 'desc',
          'text-left pl-1': (col.headerAlign || col.align) === 'left',
          'text-center': (col.headerAlign || col.align) === 'center',
          'text-right pr-1': (col.headerAlign || col.align) === 'right',
        }"
        style="margin-bottom: -1px"
      >{{ col.name }}</b-col>
        <!-- @click.meta.exact="col.sort = null" -->
        <!-- @click="cycleSort(col)" -->
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  props: {
    nav: String,
    source: String,
    columns: Array,
    variant: { type: String, default: 'primary' },
  },
  computed: {
    ...mapGetters([
      'textVariant',
      'themeVariant',
    ]),
    ...mapState({
      counter({}, { nav }) {
        return nav[this.nav].counters[this.source];
      },
    }),
    visibleColumns() {
      return this.columns.filter(c => c.visible);
    },
  },
  methods: {
    ...mapActions([
      'configureNav',
    ]),
    /**
     * FIXME: This is modifying the column spec directly and won't be persisted
     * to the store.
     */
    cycleSort(col) {
      // Clear others when fresh column is chosen
      if (col.sort === null) {
        this.clearOtherSorts(col);
      }

      // Cycle sort direction
      col.sort = col.sort === 'asc'
        ? 'desc'
        : col.sort === 'desc' ? null : 'asc'
        ;

      // When sort is left unset, all sorts are unset, so revert to defaults.
      if (col.sort === null) {
        this.setDefaultSorts();
      }

      this.configureNav({
        name: this.nav,
        columns: this.columns,
      });
    },

    clearOtherSorts({ id=undefined }) {
      this.columns.forEach((col) => {
        if (col.id === id) {
          return;  // leave active column sort alone
        }
        if (col.sort !== null) {
          col.sort = null;
        }
      });
    },

    /**
     * FIXME: The nav should specify a series of sort fields separate from the
     * column definitions' *current* sort state, so that we can support a
     * compound default sort.
     */
    setDefaultSorts() {
      const counterColumn = this.columns.find(c => c.id === this.counter);
      if (counterColumn) {
        counterColumn.sort = 'asc';
      }
    },
  },
};
</script>
