<template>
  <div :class="`text-${textVariant}`">
    <div>
      <!-- <span class="cursor-pointer" @click="setServiceStatus(!config.status)">
        <v-icon v-if="config.status === true"
          class="text-success"
        >mdi-power-plug</v-icon>
        <v-icon v-else
          class="text-danger"
        >mdi-power-plug-off</v-icon>
      </span> -->

      <a :href="config.source" target="_blank">
        {{ $t(name) }}
        <v-icon x-small :dark="dark">mdi-open-in-new</v-icon>
      </a>
    </div>
    <span v-if="config.folder">
      <v-icon :dark="dark" class="mr-2">mdi-folder-star-outline</v-icon>
      <a
        href="#"
        @click.prevent="exploreLocalFolder(localDirectory)"
      >{{ localDirectory }}</a>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    providerId: String,
    name: String,
  },
  computed: {
    ...mapGetters([
      'providers',
      'dark',
      'textVariant',
    ]),
    config() {
      return this.providers[this.providerId][this.name];
    },
    localDirectory() {
      return (this.$store.state[this.name].env || {})[this.config.folder];
    },
  },
  methods: {
    exploreLocalFolder(path) {
      window.device.openFolder(path);
    },
    setServiceStatus(status) {
      this.config.status = status;
    },
  },
};
</script>
