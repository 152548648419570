<template>
  <span class="nav-label text-nowrap">
    <v-icon v-if="icon || (userIcon && !user.photoURL)"
      :class="{
        'mr-2': true,
        [`text-${disabled
          ? dark ? 'dark' : 'light'
          : active
            ? activeTextVariant || 'light'
            : iconVariant || globalTextVariant
        }`]: true,
      }"
    >{{ icon }}</v-icon>
    <span v-if="showLabel"
      :class="{
        [`d-inline-block clip-ellipsis`]: true,
        [`text-${disabled
          ? dark ? 'dark' : 'light'
          : active
            ? activeTextVariant || 'light'
            : textVariant || globalTextVariant
        }`]: true,
        'strong': strong,
      }"
    >
      {{ $t(text || meta.activity) }}
    </span>
    <img v-if="userIcon && user.photoURL"
      width="24"
      class="mt-2 ml-2 align-top rounded-circle border border-dark"
      :src="user.photoURL"
    />
  </span>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    text: { type: String, default: null, optional: true },
    strong: { type: Boolean, default: true, optional: true },
    showLabel: { type: Boolean, default: true, optional: true },
    active: { type: Boolean, default: false, optional: true },
    icon: { type: String, default: null, optional: true },
    userIcon: { type: Boolean, default: false, optional: true },
    variant: { type: String, default: null, optional: true },
    iconVariant: { type: String, default: null, optional: true },
    textVariant: { type: String, default: null, optional: true },
    activeTextVariant: { type: String, default: null, optional: true },
    meta: { type: Object, default: () => ({}), optional: true },
    disabled: { type: Boolean, default: false, optional: true },
  },
  computed: {
    ...mapState([
      'user',
    ]),
    ...mapGetters([
      'themeVariant',
      'dark',
    ]),
    ...mapGetters({
      'globalTextVariant': 'textVariant',
    }),
  },
}
</script>

<style lang="scss">
.nav-label {
  max-width: 200px;
  i {
    vertical-align: top !important;
  }
}
</style>
