<template>
  <div :class="`text-${textVariant}`">
    <div v-if="pluginInstallQueue.length">
      {{ $tc('installing-plugins', pluginInstallQueue.length) }}
      <b-row v-for="url in pluginInstallQueue" :key="url">
        <b-col>{{ url }}</b-col>
        <b-col cols="3">
          <v-icon
            @click="removeQueuedPlugin(url)"
            :class="`text-${textVariant}`"
          >mdi-delete-circle</v-icon>
        </b-col>
      </b-row>
    </div>
    <div
      v-for="providerId in Object.keys(providers)"
      :key="providerId"
    >
      <h5>
        <v-icon large :class="`mr-2 text-${textVariant}`">{{ providerIcons[providerId] }}</v-icon>
        {{ providerId }}
      </h5>
      <div
        v-for="serviceName of services(providerId)"
        :key="`${providerId}-${serviceName}`"
        class="px-0 border-0"
      >
        <ServiceConfig :providerId="providerId" :name="serviceName"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ServiceConfig from './ServiceConfig.vue'

export default {
  data: () => ({
    providerIcons: {
      local: 'mdi-safe-square-outline',
      'mythulu.ink': 'mdi-cloud-outline',
    }
  }),
  computed: {
    ...mapState([
      'providers',
      'pluginInstallQueue',
    ]),
    ...mapState({
      name: ({ user }) => user.displayName || user.email.split('@', 1)[0],
      services: ({ providers }) => (providerId) => {
        return Object.keys(providers[providerId]).filter(name => name.startsWith('@'))
      },
    }),
    ...mapGetters([
      'textVariant',
      'themeVariant',
    ]),
  },
  methods: {
    ...mapActions([
      'removeQueuedPlugin',
    ]),
  },
  components: {
    ServiceConfig,
  },
};
</script>
