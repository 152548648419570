<template>
  <b-row no-gutters class="my-3">
    <b-col cols="2" class="text-center">
      <v-icon class="text-muted" style="font-size: 50px">mdi-share-variant</v-icon>
    </b-col>
    <b-col>
      <b-nav>
        <b-nav-item target="_blank" href="https://www.facebook.com/MythuluCards/">
          <v-icon :dark="dark">mdi-facebook</v-icon>
        </b-nav-item>
        <b-nav-item target="_blank" href="https://www.instagram.com/mythulu/?hl=en">
          <v-icon :dark="dark">mdi-instagram</v-icon>
        </b-nav-item>
        <b-nav-item target="_blank" href="https://twitter.com/mythulu">
          <v-icon :dark="dark">mdi-twitter</v-icon>
        </b-nav-item>
      </b-nav>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters([
      'dark',
    ]),
  }
};
</script>
