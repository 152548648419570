<template>
  <div
    @click="$emit('set-theme', { name, variant: defaultSubTheme.variant })"
    class="border-neutral"
    :style="themeStyle('#window', { name, variant: defaultSubTheme.variant })"
  >
    <h5 :class="`p-2 pb-5 m-0 display-5`">
      <b-row no-gutters>
        <b-col class="text-dark"
          :style="themeStyle('#logo', { name, variant: defaultSubTheme.variant })"
        >
          {{ label }}
        </b-col>
        <b-col md="auto" class="text-right">
          <v-icon
            v-for="subTheme in subThemes"
            :key="subTheme.variant"
            @click="$emit('set-theme', { name, variant: subTheme.variant })"
            small
            :class="{
              'mb-1 mx-1 p-2 border': true,
              [`text-${subTheme.textVariant} bg-${subTheme.variant}`]: true,
            }"
            :style="themeStyle('.rounded', { name, variant: subTheme.variant })"
          >mdi-square-rounded</v-icon>
        </b-col>
      </b-row>
      <!-- <b-row no-gutters>
        <p
          :class="{
            'mt-3 mb-0 mr-5 p-2': true,
            [`bg-${themeVariant} text-${textVariant}`]: true,
          }"
          style="font-size: 1rem; text-indent: 1em;"
        >
          {{ $t('sample-text') }}
        </p>
      </b-row> -->
    </h5>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    name: String,
    label: String,
    variants: Object,
  },
  computed: {
    ...mapGetters([
      'themeVariant',
      'themeStyle',
    ]),
    defaultSubTheme() {
      const impliedTheme = this.variants[this.themeVariant];
      const defaultVariant = Object.keys(this.variants)[0];
      return impliedTheme
        ? Object.assign({}, impliedTheme, { variant: this.themeVariant })
        : Object.assign({}, this.variants[defaultVariant], { variant: defaultVariant })
        ;
    },
    subThemes() {
      const themes = Object.entries(this.variants).map(
        ([variant, theme]) => Object.assign({ variant }, theme)
      );
      themes.sort(({ order: a }, { order: b }) => a - b);
      return themes;
    },
  },
};
</script>
